import { Component } from 'react';
import AppContext from '../AppContext';
import API from '../Api';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';

import 'react-toastify/dist/ReactToastify.css';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_selected: "user-info",
            firstname: "",
            lastname: "",
            username: "",
            numeric_system: null,
            email_shared_codes: false,
            email_shared_reports: false,
            email_shared_projects: false,
            email_shared_glazings: false,
            old_password: "",
            new_password: "",
            confirm_new_password: "",
            submitting: false,
            password_visible: false,
            displayModal: false
        };
    }

    componentDidMount() {
        Modal.setAppElement('body');
        if (this.props.displayModal) {
            this.setState({
                displayModal: this.props.displayModal
            })
        }
        this.setState({
            firstname: this.context.user.firstname,
            lastname: this.context.user.lastname,
            username: this.context.user.username,
            numeric_system: this.context.settings.numeric_system,
            email_shared_codes: this.context.settings.notifications?.email_shared_codes || false,
            email_shared_reports: this.context.settings.notifications?.email_shared_reports || false,
            email_shared_projects: this.context.settings.notifications?.email_shared_projects || false,
            email_shared_glazings: this.context.settings.notifications?.email_shared_glazings || false,
        })
    }

    render() {
        return (

            <div id="profile-container">
                <h1 className="page-title">ACCOUNT PROFILE</h1>
                <div id='profile-tabs'>
                    <div className='tab-list'>
                        <div onClick={() => this.setState({ tab_selected: "user-info" })} className={this.state.tab_selected === "user-info" ? "active" : ""}>USER INFO</div>
                        <div onClick={() => this.setState({ tab_selected: "app-preferences" })} className={this.state.tab_selected === "app-preferences" ? "active" : ""}>APP PREFERENCES</div>
                        <div onClick={() => this.setState({ tab_selected: "password-settings" })} className={this.state.tab_selected === "password-settings" ? "active" : ""}>PASSWORD SETTINGS</div>
                        <div onClick={() => this.setState({ tab_selected: "teams" })} className={this.state.tab_selected === "teams" ? "active" : ""}>TEAMS</div>
                    </div>

                    {(() => {
                        if (this.state.displayModal === 'true') {
                            return (
                                <Modal
                                    ariaHideApp={false}
                                    isOpen={true}
                                    onRequestClose={() => {
                                        this.setState({ displayModal: false })
                                    }}
                                    contentLabel="Example Modal"
                                    style={{
                                        overlay: {
                                            position: 'fixed',
                                            background: 'rgba(0,0,0,.5)'
                                        },
                                        content: {
                                            width: "380px",
                                            maxHeight: "80%",
                                            position: "relative",
                                            top: "50%",
                                            padding: "16px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            transform: "translateY(-50%)",
                                            transition: "opacity 2s"
                                        }
                                    }}
                                >
                                    <div>
                                        <h2>Warning:</h2>
                                        <div id="desc">
                                                <b>Complete your profile now so you can access all features...</b>
                                        </div>
                                    </div>

                                </Modal>
                            )
                        }
                    })()}

                    {(() => {
                        if (this.state.tab_selected === "user-info") {
                            return (
                                <table className='table table-condensed table-profile-styling'>
                                    <thead>
                                        <tr>
                                            <td colSpan='2' className='titles-bar'>
                                                <h1>User Info</h1>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>First Name
                                            </td>
                                            <td >
                                                <input type='text' placeholder='first name' spellCheck="false" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Last Name
                                            </td>
                                            <td >
                                                <input type='text' placeholder='last name' spellCheck="false" value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Username</td>
                                            <td>
                                                <input id='user-name' type='text' placeholder='user name' spellCheck="false" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{this.context.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan='2'>
                                                <button onClick={e => this.changeProfileInfo()} disabled={this.state.submitting} className='theme-btn no-margin' >APPLY CHANGES</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            );
                        }
                        else if (this.state.tab_selected === "app-preferences") {
                            return (
                                <table className='table table-condensed table-profile-styling' >
                                    <thead>
                                        <tr>
                                            <td colSpan='2' className='titles-bar'>
                                                <h1>App Preferences</h1>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Numeric System</td>
                                            <td>
                                                <select value={this.state.numeric_system} onChange={e => this.setState({ numeric_system: e.target.value })}>
                                                    <option value='default'>Default</option>
                                                    <option value='metric' title='Length: Meter
Area: Square Meter, 
Heat Gain & Solarloads: Watts / Square Meter
U-Value: Watts / ( Square Meter x K )
'>Metric System</option>
                                                    <option value='imperial' title='Length: Foot
Area: Square Foot, 
Heat Gain & Solarloads: BTU / ( Square Foot x Hour )
R-Value: ( Square Foot x Farenheit x Hour ) /  BTU
                                                        '>Imperial System</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan='2' className='titles-bar'>
                                                <h1>Email Notifications</h1>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>Shared reports</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" defaultChecked={this.state.email_shared_reports} onChange={e => this.setState({ email_shared_reports: e.target.checked })} className="custom-control-input" id="email-notification-shared-reports" />
                                                    <label className="custom-control-label" htmlFor="email-notification-shared-reports"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shared codes</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" defaultChecked={this.state.email_shared_codes} onChange={e => this.setState({ email_shared_codes: e.target.checked })} className="custom-control-input" id="email-notification-shared-codes" />
                                                    <label className="custom-control-label" htmlFor="email-notification-shared-codes"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shared projects</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" defaultChecked={this.state.email_shared_projects} onChange={e => this.setState({ email_shared_projects: e.target.checked })} className="custom-control-input" id="email-notification-shared-projects" />
                                                    <label className="custom-control-label" htmlFor="email-notification-shared-projects"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shared glazing make up</td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" defaultChecked={this.state.email_shared_glazings} onChange={e => this.setState({ email_shared_glazings: e.target.checked })} className="custom-control-input" id="email-notification-shared-glazings" />
                                                    <label className="custom-control-label" htmlFor="email-notification-shared-glazings"></label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button onClick={e => this.changeAppPreferences()} disabled={this.state.submitting} className='theme-btn no-margin'>APPLY CHANGES</button>
                                            </td>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            );
                        }
                        else if (this.state.tab_selected === "password-settings") {
                            return (
                                <table className='table table-condensed table-profile-styling'>
                                    <thead>
                                        <tr>
                                            <td colSpan={2} className='titles-bar'>
                                                <h1>Password Settings </h1>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>

                                            </td>
                                            <td>
                                                <button className="theme-btn no-margin" onClick={() => this.setState({ password_visible: !this.state.password_visible })}>Toggle Visibility</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Old Password
                                            </td>
                                            <td>
                                                <input type={this.state.password_visible ? "text" : "password"} placeholder='Old Password' value={this.state.old_password} onChange={e => this.setState({ old_password: e.target.value })} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                New Password
                                            </td>
                                            <td>
                                                <input type={this.state.password_visible ? "text" : "password"} placeholder='New Password' value={this.state.new_password} onChange={e => this.setState({ new_password: e.target.value })} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Confirm New Password
                                            </td>
                                            <td>
                                                <input type={this.state.password_visible ? "text" : "password"} placeholder='Confirm New Password' value={this.state.confirm_new_password} onChange={e => this.setState({ confirm_new_password: e.target.value })} required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button onClick={e => this.changePassword()} disabled={this.state.submitting} className='theme-btn no-margin'>
                                                    APPLY CHANGES
                                                </button>
                                            </td>
                                            <td>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            );
                        }
                        else if (this.state.tab_selected === "teams") {
                            return (
                                <table className='table table-condensed table-profile-styling'>
                                    <thead>
                                        <tr>
                                            <td colSpan='2' className='titles-bar'>
                                                <h1>Teams</h1>
                                            </td>
                                        </tr>
                                    </thead>
                                    {this.showTeams()}
                                </table>
                            );
                        }
                    })()}
                </div>
                <ToastContainer position="bottom-right" autoClose="1500" />
            </div>
        );
    }

    showTeams() {
        return (
            <tbody>
                {this.context.user.teams.length === 0 ? <tr className='no-teams'>
                    <td>You are not part of any team at the moment</td>
                </tr> :
                    this.context.user.teams.map(team => {
                        console.log(team.id)
                        return (
                            <tr key={team.id}>
                                <td>{team.name}</td>
                                <td>{team.users.map(u => u.email).join(", ")}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    changeProfileInfo() {
        this.setState({ submitting: true });
        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let username = this.state.username;
        API.editProfileInfo(
            firstname,
            lastname,
            username
        )
            .then(response => {
                this.context.user.firstname = firstname;
                this.context.user.lastname = lastname;
                this.context.user.username = username;
                toast.success("PROFILE UPDATED");
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({ submitting: false });
            });
    }

    changeAppPreferences() {
        this.setState({ submitting: true });
        let numeric_system = this.state.numeric_system;
        let email_shared_codes = this.state.email_shared_codes;
        let email_shared_reports = this.state.email_shared_reports;
        let email_shared_projects = this.state.email_shared_projects;
        let email_shared_glazings = this.state.email_shared_glazings;
        API.editAppPreferences(
            numeric_system,
            email_shared_codes,
            email_shared_reports,
            email_shared_projects,
            email_shared_glazings
        )
            .then(response => {
                
                this.context.settings.numeric_system = numeric_system;
                this.context.settings.notifications.email_shared_codes = email_shared_codes;
                this.context.settings.notifications.email_shared_reports = email_shared_reports;
                this.context.settings.notifications.email_shared_projects = email_shared_projects;
                this.context.settings.notifications.email_shared_glazings = email_shared_glazings;
                
                toast.success("APP PREFERENCES UPDATED");
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({ submitting: false });
            });;
    }

    changePassword() {
        let old_password = this.state.old_password;
        let new_password = this.state.new_password;
        let confirm_new_password = this.state.confirm_new_password;

        if (new_password.length < 6) {
            toast.error("The new password is too short, it must be at least 6 charachters long.", { autoClose: 5000 });
        }
        else if (new_password !== confirm_new_password) {
            toast.error("\"New Password\" and \"Confirm New Password\" mismatch", { autoClose: 5000 });
        }
        else {
            this.setState({ submitting: true });
            API.changePassword(old_password, new_password)
                .then(response => {
                    toast.success("PASSWORD UPDATED");
                })
                .catch(error => {
                    toast.error(error.responseText, { autoClose: 5000 });
                })
                .always(() => {
                    this.setState({ submitting: false });
                });
        }
    }

}

UserProfile.contextType = AppContext;

export default UserProfile;