import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RedirectFromPlatform from './RedirectFromPlatform';
import Redirect from './Redirect';
import MainLayout from './MainLayout';
import '../styles/App.scss';

class App extends Component {

  render() {

    return (
      <Router>
        <Switch>
          <Route path="/from-platform/:token">
            <RedirectFromPlatform />
          </Route>
          <Route path="/guardian_verification/:id">
            <Redirect />
          </Route>
          <Route path="/verify/:id">
            <Redirect />
          </Route>
          <Route path="/admin_user_activation">
            <Redirect />
          </Route>
          <Route path="/recovery">
            <Redirect />
          </Route>
          <Route path="/" component={MainLayout} />
        </Switch>
      </Router>
    );
  }
}

export default App;