// banner component with TITLE and DESCRIPTION
import ReactTimeAgo from 'react-time-ago';

/*
props: image, title, subtitle, creator, created, logo, settings
*/

function Tile(props) {
  return (
    <div className="tile" id="tile" title={props.title} onClick={(e) => { 
      props.tileClick(e, props.tileClickData) 
      }}>
      {props.img ?
        <div className='fpe-thumbnail-container'>
          <img className="fpe-thumbnail" src={props.img} alt="project thumbnail" />
        </div> : null}
      {props.title ? <div className="name-tile">{props.title}</div> : null}
      {props.subtitle ? <div className="subname-tile">{props.subtitle}</div> : null}
      {props.details ? <div className="details-tile">{props.details}</div> : null}
      {props.created ? <div className="details-tile">Created: <ReactTimeAgo date={Date.parse(props.created)} locale="en-US" /></div> : null}
      {props.creator ? <div className="details-tile">{props.creator}</div> : null}
      {props.note ? <div className="details-tile">{props.note}</div> : null}
      <br /> <br /> <br />
      {props.logoIcon ? <img src={props.logoIcon} className="file-type" alt="project file extension" /> : null}
      {props.settingsIcon ? <img src={props.settingsIcon} className="setting-report icon-zoom" onClick={(e) => props.settingsClick(e, props.settingsData)} title="Options" alt="settings icon" /> : null}
    </div>
  )
}

export default Tile