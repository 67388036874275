import { Component } from 'react';
import API from '../Api';
import Cookies from 'js-cookie';
import '../styles/LoadingLayer.scss';

class RedirectFromPlatform extends Component {

    componentDidMount() {
        let param = window.location.search
        if (param) {
            let key = window.location.search.substring(1).split("=")[0]
            let value = window.location.search.substring(1).split("=")[1]
            //console.log(`key:${key} - value:${value}`)
            if (key === 'displayModal') {
                param = `/${value}`
            }
        }
        let token = window.location.pathname.split("/")[2];
        let redirect_path = window.location.pathname.replace("/from-platform/" + token, "") + param;
        //console.log(redirect_path)
        API.tokenSignin(token)
            .then(response => {
                Cookies.set("token", response.token, { SameSite: 'Strict'});
                //API.authToken = response.token;
                window.location = redirect_path;
            })
            .catch(error => {
                console.log(error)
                alert(error.responseJSON)
                window.location = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || 'http://localhost:8080') + "/signin";
            });
    }

    render() {
        return <div className="loading">Loading&#8230;</div>;
    }
}

export default RedirectFromPlatform;