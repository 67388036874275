module.exports = {

    init: function (numeric_system) {

        if (numeric_system === 'default') {
            //if user has not changed their numeric-system then it will be set to 'default'
            numeric_system = 'metric';
        }

        let values = {};
        if (numeric_system === 'imperial') {
            values = {
                type: "imperial",
                length_unit: "ft",
                area_unit: "ft²",
                uv_unit: "(ft² hr F)/BTU",
                r_label: "R-Value",
                r_unit: "BTU/(ft² hr F)",
                uv_label_short: "R-VAL",
                hg_unit: "BTU/(ft² hr)",
            }
        } else if (numeric_system === 'metric') {
            values = {
                type: "metric",
                length_unit: "m",
                area_unit: "m²",
                uv_unit: "W/(m²K)",
                r_label: "U-Value",
                r_unit: "W/(m²K)",
                uv_label_short: "U-VAL",
                hg_unit: "W/m²",
            }
        }
        /*
        $('.length-unit').text(this.length_unit);
        $('.area-unit').text(this.area_unit);
        $('.uv-unit').text(this.uv_unit);
        $('.uv-label').text(this.uv_label);
        $('.uv-label-short').text(this.uv_label_short);
        $('.hg-unit').text(this.hg_unit);
        */
        return values;
    },

    length_val: function (numeric_system,value) {
        //meters to feets
        if (numeric_system === "imperial") {
            value *= 3.28084;
        }
        return parseFloat(Number(value).toFixed(3));
    },

    area_val: function (numeric_system, value) {
        //square meters to square feets
        if (numeric_system === "imperial") {
            value *= 10.7639;
        }
        return parseFloat(Number(value).toFixed(3));
    },

    hg_val: function (numeric_system, value) {
        if (numeric_system === "imperial") {
            value *= 0.317;
        }
        return parseFloat(Number(value).toFixed(3));
    },

    uv_val: function (numeric_system, value) {
        if (numeric_system === "imperial") {
            if (value > 0) {
                value = 5.678263 / value;
            }
        }
        return parseFloat(Number(value).toFixed(3));
    },

    uv_val_invert: function (numeric_system, value) {
        if (numeric_system === "imperial") {
            if (value > 0) {
                value = 5.678263 * value;
            }
        }
        return parseFloat(Number(value).toFixed(3));
    },

    uv_glass_val: function (numeric_system, value) {
        if (numeric_system === "imperial") {
            value = value / 5.678263;
        }
        return parseFloat(Number(value).toFixed(3));
    },

    uv_glass_val_invert: function (numeric_system, value) {
        if (numeric_system === "imperial") {
            value = value * 5.678263;
        }
        return parseFloat(Number(value).toFixed(3));
    },
};
