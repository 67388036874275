import { Component } from 'react';
import Modal from 'react-modal';
import AppContext from '../AppContext';
import API from '../Api';
import ShareOptions from './ShareOptions';
import Reviticon from "../img/revit_icon.png";
import LoadingSquaresGif from "../img/loading-squares.gif";
import GearIcon from "../img/gear_icon.png";
import Tile from "./Tile";


class FpeProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            search: "",
            fetching: true,
            submitting: false,
            project_detail: null,
            projects_modal_mode: "options",
            noContentMsg: "none",
        }
    }

    componentDidMount() {
        API.getFpeProjects()
            .then(projects => {
                this.setState({
                    projects: projects,
                    fetching: false
                }, () => {
                    if (this.state.projects.length > 0) {
                        this.state.projects.forEach(project => {
                            let pid = project._id;
    
                            API.getFpeThumbnail(pid)
                                .then(result => {
                                    this.setState({
                                        projects: this.state.projects.map(p => {
                                            if (p._id === pid) {
                                                p.file.thumbnail_base64 = result.file.thumbnail_base64;
                                            }
                                            return p;
                                        })
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        })
                    } else {
                        this.setState({noContentMsg: "block"});
                    } 
                });
            })
            .catch(error => {
                console.warn("Error");
            });
    }

    render() {
        return (
            <div id="fpe-projects-page">
                <h1 className="page-title">PROJECTS </h1>
                <div id="projects-container-tiles">
                    <div id="search-container">
                        Search: <input id="search" onChange={(e) => this.setState({ search: e.target.value.toLowerCase().trim() })} />
                    </div>
                    <div id="empty-message" style={{display: this.state.noContentMsg}}>
                        <span>No projects to display.</span>
                    </div>
                    <div id="tiles">
                        {(() => this.showProjects())()}
                    </div>
                </div>
                {(() => {
                    if (this.state.project_detail != null) {
                        return (
                            <Modal ariaHideApp={false} isOpen={true} onRequestClose={() => this.setState({ project_detail: null, project_modal_mode: "options" })} contentLabel="Example Modal"
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        background: 'rgba(0,0,0,.5)'
                                    },
                                    content: {
                                        width: "600px",
                                        maxHeight: "80%",
                                        position: "relative",
                                        top: "50%",
                                        padding: "16px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        transform: "translateY(-50%)",
                                        transition: "opacity 2s"
                                    }
                                }}
                            >
                                <div id='project-modal-content'>
                                    <div className="fpe-file-name-modal">{this.state.project_detail.file.name}</div>
                                    <img className="fpe-thumbnail-modal" src={this.state.project_detail.file.hasOwnProperty("thumbnail_base64") ? "data:image/png;base64, " + this.state.project_detail.file.thumbnail_base64 : LoadingSquaresGif} alt="project thumbnail" />
                                </div>
                                <div id='project-modal-mode-container'>
                                    {(() => {
                                        if (this.state.projects_modal_mode === "options") {
                                            return (
                                                <div>
                                                    <button className="theme-btn delete-btn" onClick={e => this.deleteProject()} disabled={this.state.submitting}>Delete Project</button>
                                                    <button className="theme-btn" onClick={e => this.setState({ projects_modal_mode: "share" })} disabled={this.state.submitting}>Share Project</button>
                                                </div>
                                            )
                                        }
                                        else if (this.state.projects_modal_mode === "share") {
                                            return (
                                                <div>
                                                    <ShareOptions share_item="fpe_projects" item_id={this.state.project_detail._id} />
                                                    <button className="go-back" onClick={() => this.setState({ projects_modal_mode: "options" })}>« Go Back</button>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </Modal>
                        )
                    }
                })()}
            </div>
        )
    }

    showProjects() {
        return this.state.projects
            .filter(project => {
                if (this.state.search === "") {
                    return true;
                }
                else {
                    return project.file.name.toLowerCase().includes(this.state.search);
                }
            })
            .map(project => {
                let email;
                if (project.user_id) {
                    email = project.user_id.email
                } else {
                    email = 'unknown';
                }
                return (
                    <Tile
                        key={project._id}
                        img={project.file.hasOwnProperty("thumbnail_base64") ? "data:image/png;base64, " + project.file.thumbnail_base64 : LoadingSquaresGif}
                        tileClick={this.openProject}
                        tileClickData={project._id}
                        title={`Project: ${project.file.name.split(".")[0]}`}
                        creator={`Author: ${email}`}
                        logoIcon={Reviticon}
                        settingsIcon={GearIcon}
                        settingsClick={this.openSettings}
                        settingsData={project}
                    />
                );
            });
    }
    
    deleteProject() {
        let id = this.state.project_detail._id;
        if (window.confirm("Confirm that you want to delete this project.")) {
            this.setState({ submitting: true });
            API.deleteFpeProject(id)
                .then(response => {
                    console.log(response);
                    this.setState({
                        projects: this.state.projects.filter(p => p._id !== id),
                        project_detail: null,
                        project_modal_mode: "options"
                    });
                })
                .catch(error => {
                    console.log(error);
                })
                .always(() => {
                    this.setState({ submitting: false });
                });
        }
    }

    openProject = (e, id) => {
        window.location = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || 'http://localhost:8080') + "/fpe_app?id=" + id
    }

    openSettings = (e, project) => {
        e.stopPropagation();
        this.setState({
            project_detail: project,
            projects_modal_mode: "options"
        });
    }
}

FpeProjects.contextType = AppContext;
export default FpeProjects;