import API from './Api';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

//It opens the learnupon website in a new tab with the user already logged in.
export function openLearnUponPlatform(){
    window.open('https://fenestrapro.com/lms-platform/', '_blank').focus();
    /* API.getLearnUponSSO()
    .then(sso => {
        toast.success("REDIRECTING TO LEARNUPON");
        window.open(sso, "_blank");
    })
    .catch(error => {
        //console.warn(error);
        toast.error(error.responseText, { autoClose: 5000 });
        window.location = "/profile/true";
    }); */
}