import React from 'react';
import { Switch, Route, Link } from "react-router-dom";
import Cookies from 'js-cookie';
//
import API from '../Api';
import { openLearnUponPlatform } from '../Utils';
import AppContext from '../AppContext';
import Home from './Home';
import UserProfile from './UserProfile';
import GlassDatabase from './GlassDatabase';
import FpeProjects from './FpeProjects';
//import _FpeApp from './FpeApp/_FpeApp';
import Reports from './Reports';
import Report from './Report';
import Codes from './Codes';
import Admin from './Admin';
import AsmDashboard from './AsmDashboard';
import HomeIcon from "../img/new-home.svg";
import ProjectsIcon from "../img/new-3d-building.svg";
import AdminIcon from "../img/admin_icon.png";
import LogoutIcon from "../img/exit.png";
import ReportsIcon from "../img/new-analytics.svg";
import CodesIcon from "../img/new-advanced-settings.svg";
import ProfileIcon from "../img/new-user-icon.svg";
import GlassDatabaseIcon from "../img/new-window-icon.svg";
import GuardianIcon from "../img/vendors/guardian_symbol_white_transparent.png";
import LmsIcon from "../img/new-knowledge.svg";
import Theme from './Theme';
import { GUARDIAN_EMAIL_REGEX } from '../Regex';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LoadingLayer.scss';

class MainLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loaded: false,
            user: {
                email: "",
                firstname: "",
                lastname: "",
                platform_type: "",
                teams: [],
                username: "",
                id: "",
                _id: "",
                is_main: false,
                organization_details: {
                    id: "",
                    _id: "",
                    name: "",
                    end_date: "",
                    license_key: "",
                    trial: false,
                }
            }
        }
    }

    componentDidMount() {
        Promise.all([
            API.getProfileInfo(),
            API.getUserSettings(),
            API.getColleagues(),
        ])
            .then(results => {
                this.setState({
                    is_loaded: true,
                    user: results[0],
                    settings: results[1],
                    colleagues: results[2].users.filter(u => u.email !== results[0].email)
                }, () => {
                    this.changeAppHeaders()
                });
            })
            .catch(error => {
                //console.log(error)
                let redirect_route
                if (window.location.pathname === '/') {
                    redirect_route = '/home'
                } else {
                    redirect_route = window.location.pathname
                }
                window.location = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || 'http://localhost:8080') + "/signin?react_pathname=" + redirect_route
            });
    }

    render() {

        if (this.state.is_loaded === true) {
            //const LOGOUT_LINK = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || "http://localhost:8080") + "/signin?pt=" + this.state.user.platform_type;
            return (
                <AppContext.Provider value={{
                    user: this.state.user,
                    settings: this.state.settings,
                    colleagues: this.state.colleagues
                }}>
                    <Theme />
                    <div id='topbar'>
                        <Link to="/home">
                            <div id='logo-brand' title="Home" ></div>
                        </Link>
                        <div id="topbar-menu">
                            {this.state.user.is_main === true ? <Link className='topbar-item' title="Admin" to="/admin_settings"><img className='topbar-item-icon' src={AdminIcon} alt="Admin" /></Link> : ""}
                            <a id='logout' className='topbar-item' title="Log me out" onClick={() => this.logout()}>
                                <img className='topbar-item-icon' src={LogoutIcon} alt="Logout" />
                            </a>

                        </div>
                    </div>

                    <div id="side-bar">


                        <Link className="sidebar-link" to="/" title="Home">
                            HOME
                            <img src={HomeIcon} alt="Home" />
                        </Link>
                        {
                            GUARDIAN_EMAIL_REGEX.test(this.state.user.email) ?
                                <Link className="sidebar-link" to='/vendor_management' >
                                    LICENSE MANAGEMENT
                                    <img src={GuardianIcon} alt="Guardian" />
                                </Link>
                                :
                                ""
                        }
                        <Link className="sidebar-link" to="/fpe_projects" title="Projects">
                            PROJECTS
                            <img src={ProjectsIcon} alt="Projects" />
                        </Link>

                        <Link className="sidebar-link" to="/reports" title="Reports">
                            REPORTS
                            <img src={ReportsIcon} alt="Reports" />
                        </Link>

                        <Link className="sidebar-link" to="/codes" title="Codes">
                            CODES
                            <img src={CodesIcon} alt="Codes" />
                        </Link>

                        <Link className="sidebar-link" to="/glassdatabase" title="Glass Database">
                            GLASS DATABASE
                            <img src={GlassDatabaseIcon} alt="Glass Database" />
                        </Link>

                        <Link className="sidebar-link" to="/profile" title="Profile">
                            ACCOUNT PROFILE
                            <img src={ProfileIcon} alt="Profile" />
                        </Link>

                        <div className="sidebar-link" onClick={() => openLearnUponPlatform()} title="Learning Platform">
                            LEARNING
                            <img src={LmsIcon} alt="Learning" />
                        </div>
                    </div>
                    <div id="layout-body">
                        <Switch>
                            <Route exact path="/" component={Home}></Route>
                            <Route path="/home" component={Home}></Route>
                            <Route exact path="/profile" component={UserProfile}></Route>
                            <Route path="/profile/:displayModal" render={(props) => <UserProfile displayModal={props.match.params.displayModal} /> } />
                            <Route path="/glassdatabase" component={GlassDatabase}></Route>
                            <Route path="/fpe_projects" component={FpeProjects}></Route>
                            {/* <Route path="/fpe_app" component={_FpeApp}></Route> */}
                            <Route path="/codes" component={Codes}></Route>
                            <Route path="/reports" component={Reports}></Route>
                            <Route path="/report/:reportId" render={(props) => <Report reportId={props.match.params.reportId} /> } />
                            {this.state.user.is_main === true ? <Route path="/admin_settings" component={Admin}></Route> : ""}
                            {GUARDIAN_EMAIL_REGEX.test(this.state.user.email) ? <Route path="/vendor_management" component={AsmDashboard}></Route> : ""}
                        </Switch>
                    </div>
                </AppContext.Provider>
            );
        }
        else {
            return <div className="loading">Loading&#8230;</div>
        }
    }

    logout() {
        Cookies.remove('token');
        Cookies.remove('third_party_token');
        /*
        Cookies.remove('token', { path: '/', domain: 'localhost' });
        Cookies.remove('third_party_token', { path: '/', domain: 'localhost' });
        */

        // window.location = '/'
        window.location = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || "http://localhost:8080") + "/signin?react_pathname=/home&pt=" + this.state.user.platform_type;
    }

    changeAppHeaders() {
        let favicon_el = document.getElementById("favicon");
        if (this.state.user.platform_type === "guardian") {
            favicon_el.href = "favicons/guardian_favicon.ico";
            document.title = "Guardian Glass for BIM";
        }
        else {
            favicon_el.href = "favicons/fenestrapro_favicon.ico";
            document.title = "FenestraPro Platform";
        }
    }
}

export default MainLayout;