import React from 'react';
import AppContext from '../AppContext';
import API from '../Api';
import LoadingSquaresGif from "../img/loading-squares.gif";
import { Container, Row, Col } from "react-bootstrap";
// dataTable
import DataTable from 'react-data-table-component';
import CsvDownloader from 'react-csv-downloader';
// logos
import VitroLincIcon from '../img/vendors/vitro_linc.svg';
import GuardianLincIcon from '../img/vendors/guardian_linc.svg';
import FenestraProLincIcon from '../img/vendors/fenestrapro_linc.svg';
import ViraconLincIcon from '../img/vendors/viracon_linc.svg';
// highcharts
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; //for rendering spherical
// modules
import unit_converter from '../modules/unit_converter';
//import { reduceEachTrailingCommentRange } from 'typescript';
//import { unstable_concurrentAct } from 'react-dom/test-utils';

HC_exporting(Highcharts)
HighchartsMore(Highcharts); //for rendering spherical

class Report extends React.Component {

    constructor(props) {
        super(props);
        //this.chart = React.createRef();
        this.state = {
            exportButtonLabel: 'Export to CSV',
            numeric_codes: null,
            report: null,
            search: "",
            fetching: true,
            displayDefault: true,
            chartData: null,
            uniqueOrientations: null,
            solarRadiationChart: null,
            heatGainChart: null,
            daylightingChart: null,
            allDataChart: null,
            overlayChart: null,
            glazings: null,
            summaryTableColumns: [
                {
                    id: 1,
                    name: 'ITEM',
                    selector: 'item',
                },
                {
                    id: 2,
                    name: 'INFO',
                    selector: 'info',
                }
            ],
            summaryTableData: null,
            glazingTableColumns: [
                {
                    id: 1,
                    name: 'VENDOR',
                    selector: 'vendor',
                    sortable: true,
                    cell: d => <img className='vendor-logo' src={d.img} alt="vendor logo" />,
                    grow: 0.5,
                },
                {
                    id: 2,
                    name: 'DESC',
                    selector: 'desc',
                    sortable: true,
                    wrap: true,
                    grow: 2,
                },
                {
                    id: 3,
                    name: 'UV',
                    selector: 'uv',
                    sortable: true,
                    grow: 0.25,
                },
                {
                    id: 4,
                    name: 'SHGC',
                    selector: 'shgc',
                    sortable: true,
                    grow: 0.25,
                },
                {
                    id: 5,
                    name: 'VLT',
                    selector: 'vlt',
                    sortable: true,
                    grow: 0.25,
                },
                {
                    id: 6,
                    name: 'ON FACADES...',
                    selector: 'facade',
                    sortable: true,
                    wrap: true,
                },
                {
                    id: 7,
                    name: 'ON SURFACES...',
                    selector: 'surfaces',
                    sortable: true,
                    wrap: true,
                },
                {
                    id: 8,
                    name: 'CALC. STANDARD',
                    selector: 'calc_standard',
                    sortable: true,
                },
                {
                    id: 9,
                    name: 'PROGRAM VER.',
                    selector: 'program_ver',
                    sortable: true,
                }
            ],
            glazingTableData: null,
        }
    }

    componentDidMount() {
        //this.chart = this.refs.chart.chart;
        // check user unit preference
        API.getUserSettings(this.context.user.id).then((userSettings) => {
            this.setState({ numeric_codes: unit_converter.init(userSettings.numeric_system) }, () => {
                //console.log(this.state.numeric_codes);
                // fetch report
                console.log(this.props.reportId)
                API.getReportDataById(this.props.reportId)
                    .then(report => {
                        console.log(report)
                        this.setState({ report }, () => {
                            console.log(this.state.report);
                        });
                    })
                    .then(() => {
                        //populate summaryTableData state
                        this.setState({ summaryTableData: this.buildSummaryTableData(this.state.report) });
                    })
                    .then(() => {
                        //parse report for all chart related datasets and save to state
                        this.setState({ chartData: this.parseChartData(this.state.report) });
                    })
                    .then(() => {
                        let uniqueOrientations = [...new Set(this.state.chartData.map((entry) => entry.orientation))]
                        this.setState({ uniqueOrientations })
                    })
                    .then(() => {
                        //build SolarRadiationChart graphic...
                        this.setState({ solarRadiationChart: this.buildSolarRadiationChart(this.state.chartData) });
                    })
                    .then(() => {
                        //build HeatGainChart graphic...
                        this.setState({ heatGainChart: this.buildHeatGainChart(this.state.chartData) });
                    })
                    .then(() => {
                        //build daylightingChart graphic...
                        this.setState({ daylightingChart: this.buildDaylightingChart(this.state.chartData) });
                    })
                    .then(() => {
                        //buildOverlayChart graphic...
                        this.setState({ overlayChart: this.buildOverlayChart(this.state.chartData) });
                    })
                    .then(() => {
                        //buildAllDataChart graphic...
                        this.setState({ allDataChart: this.buildAllDataChart(this.state.chartData) });
                    })
                    .then(() => {
                        let unique_glass_ids = [...new Set(this.state.report.data.openings.map(op => op.glass_id))].filter(id => id != null && id.length === 24);
                        //console.log(unique_glass_ids);
                        //
                        Promise.all(unique_glass_ids.map(glass_id => API.getGlassInformation(glass_id)))
                            .then(responses => {

                                let glazings = responses.filter(item => item !== "not found");
                                let surfaceToFacade = {};
                                let projectDetailsObj = {};

                                this.state.report.data.surfaces.forEach(s => {
                                    surfaceToFacade[s.id] = s.facade_id;
                                });

                                this.state.report.data.openings.forEach((opening, i) => {
                                    let glassID = opening.glass_id;
                                    if (!projectDetailsObj[glassID]) {
                                        projectDetailsObj[glassID] = {
                                            surface_ids: opening.surface_id || [],
                                            facade_ids: []
                                        }
                                    } else {
                                        opening.surface_id.forEach(surfaceId => {
                                            if (!projectDetailsObj[glassID].surface_ids.includes(surfaceId)) {
                                                projectDetailsObj[glassID].surface_ids.push(surfaceId)
                                            }
                                        });
                                    }
                                });

                                Object.keys(projectDetailsObj).forEach(glassID => {
                                    let surface_ids = projectDetailsObj[glassID].surface_ids;
                                    surface_ids.forEach(surfID => {
                                        let facadeID = surfaceToFacade[surfID];
                                        if (!projectDetailsObj[glassID].facade_ids.includes(facadeID)) {
                                            projectDetailsObj[glassID].facade_ids.push(facadeID);
                                        }
                                    })
                                });

                                glazings = glazings.map(glass => {
                                    return Object.assign({}, glass, {
                                        onFacades: projectDetailsObj[glass._id].facade_ids,
                                        onSurfaces: projectDetailsObj[glass._id].surface_ids,
                                        program_version: `${glass.glass_analytics_program_version} ${glass.glass_analytics_database_version}` === "undefined undefined" ? "N/A" : `${glass.glass_analytics_program_version} ${glass.glass_analytics_database_version}`
                                    });
                                });
                                //console.log(glazings);
                                this.setState({ glazings }, () => {
                                    this.setState({ glazingTableData: this.buildGlazingTableData() });
                                    console.log('done 1')
                                });
                            }).then(() => {
                                this.setState({ fetching: false }, () => {
                                    console.log('done 2')
                                });
                            }).catch(error => {
                                console.log(error);
                            });
                    }).catch(error => {
                        console.log(error);
                    });
            });
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        if (this.state.fetching) {
            return (
                <div className="loader">
                    <img src={LoadingSquaresGif} alt="project thumbnail" />
                </div>
            )
        } else {
            return (
                <div id="report-page">
                    <Container fluid className='p-0 containerStyle'>
                        <Row className='rowStyle'>
                            <h1 className="tableHeaders">{`Report: ${this.state.report.name}`}</h1>
                        </Row>
                        <Row className='rowStyle'>
                            <Col className='colStyle' sm md={4} lg={4} xl={4} xxl={4}>
                                <h2>Overview</h2>
                                <CsvDownloader className='csvDownloader'
                                    filename="table_export"
                                    extension=".csv"
                                    separator=";"
                                    datas={
                                        this.state.summaryTableData.map((entry) => {
                                            const summaryData = {
                                                ITEM: entry.item,
                                                INFO: entry.info
                                            };
                                            return summaryData;
                                        })
                                    }
                                >
                                    <button className='csvButton'>{this.state.exportButtonLabel}</button>
                                </CsvDownloader>
                                <DataTable
                                    //keyField='name'
                                    columns={this.state.summaryTableColumns}
                                    data={this.state.summaryTableData}
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    dense={true}
                                    striped={false}
                                    pagination={false}
                                />
                            </Col>
                            <Col className='colStyle' sm md={8} lg={8} xl={8} xxl={8}>
                                <h2>Solar Chart</h2>
                                <div>
                                    <HighchartsReact
                                        id="solarChart"
                                        highcharts={Highcharts}
                                        //highcharts={this.props.highcharts}
                                        //constructorType={"chart"} 
                                        //ref={this.chart} 
                                        options={this.state.solarRadiationChart}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className='rowStyle'>
                            <Col className='colStyle' sm md={12} lg={6} xl={6} xxl={6}>
                                <h2>Orientation: All Data </h2>
                                <div>
                                    <HighchartsReact id="allDataChart" highcharts={Highcharts} options={this.state.allDataChart} />
                                </div>
                            </Col>
                            <Col className='colStyle' sm md={12} lg={6} xl={6} xxl={6}>
                                <h2>{`Surface-Level: Heat-Gain & Daylighting`}</h2>
                                <div>
                                    <HighchartsReact id="overlayChart" highcharts={Highcharts} options={this.state.overlayChart} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='rowStyle'>
                            <Col className='colStyle' sm md={12} lg={6} xl={6} xxl={6}>
                                <h2>Surface-Level Heat-Gain</h2>
                                <div>
                                    <HighchartsReact id="heatGainChart" highcharts={Highcharts} options={this.state.heatGainChart} />
                                </div>
                            </Col>
                            <Col className='colStyle' sm md={12} lg={6} xl={6} xxl={6}>
                                <h2>Surface-Level Daylighting</h2>
                                <div>
                                    <HighchartsReact id="daylightingChart" highcharts={Highcharts} options={this.state.daylightingChart} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='rowStyle'>
                            <Col className='colStyle' sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <h2>Glazing Make Up</h2>
                                <CsvDownloader
                                    className="csvDownloader"
                                    filename="table_export"
                                    extension=".csv"
                                    separator=";"
                                    datas={this.state.glazingTableData.map((entry) => {
                                        const glazingData = {
                                            VENDOR: entry.vendor,
                                            DESC: entry.desc,
                                            UV: entry.uv,
                                            SHGC: entry.shgc,
                                            VLT: entry.vlt,
                                            ON_FACADES: entry.facade.replaceAll(",", ", "),
                                            ON_SURFACES: entry.surfaces.replaceAll(",", ", "),
                                            CALC_STANDARD: entry.calc_standard,
                                            PROG_VER: entry.program_ver,
                                        };
                                        return glazingData;
                                    })}
                                >
                                    <button className='csvButton'>{this.state.exportButtonLabel}</button>
                                </CsvDownloader>
                                <DataTable
                                    //keyField='name'
                                    columns={this.state.glazingTableColumns}
                                    data={this.state.glazingTableData}
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    dense={true}
                                    striped={false}
                                    pagination={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </div >
            )
        }
    }

    searchTerm(term) {
        this.setState({ search: term.toLowerCase().trim() });
    }

    parseChartData(report) {
        let data = report.data.surfaces.map((surface, index) => {
            let result = {};
            result.name = `surface ${index}`;
            result.orientation = surface.orientation;
            result.area = surface.area;
            result.areaGlazed = surface.areaGlazed;
            result.daylighting = surface.daylighting;
            result.heatGainAve = surface.heatGain[12];
            result.solarLoadAve = surface.solarLoad[12];
            result.solarLoadPerOrientation = surface.solarLoad.map(solarLoad => {
                return solarLoad;
            })
            return result;
        });
        //console.log(data);
        return data;
    }

    buildAllDataChart(data) {
        //console.log(data);
        function createTotalsObj() {
            const totalsObj = {};
            totalsObj.count = 0;
            totalsObj.area = 0;
            totalsObj.areaGlazed = 0;
            totalsObj.areaGlazedRatio = 0;
            totalsObj.daylighting = 0;
            totalsObj.daylightingAve = 0;
            totalsObj.heatGain = 0;
            totalsObj.heatGainAve = 0;
            totalsObj.solarLoad = 0;
            totalsObj.solarLoadAve = 0;
            return totalsObj;
        };
        let totals = {}
        this.state.uniqueOrientations.forEach(orientation => {
            totals[orientation] = createTotalsObj();
        })
        data.map(s => {
            let temp = s.orientation;
            totals[temp].count += 1;
            totals[temp].area += s.area;
            totals[temp].areaGlazed += s.areaGlazed;
            totals[temp].daylighting += s.daylighting;
            totals[temp].heatGain += s.heatGainAve;
            totals[temp].solarLoad += s.solarLoadAve;
            return null;
        });
        Object.keys(totals).map((key) => {
            totals[key].areaGlazedRatio = (totals[key].areaGlazed / totals[key].area) * 100;
            totals[key].daylightingAve = totals[key].daylighting / totals[key].count;
            totals[key].heatGainAve = totals[key].heatGain / totals[key].count;
            totals[key].solarLoadAve = totals[key].solarLoad / totals[key].count;
            return null;
        });
        //console.log(totals);
        //
        let series = {};
        series.area = {
            name: 'Area',
            type: 'spline',
            color: Highcharts.getOptions().colors[1],
            tooltip: {
                valueSuffix: ` ${this.state.numeric_codes.area_unit}`
            },
            data: [
                unit_converter.area_val(this.state.numeric_codes.type, totals.S.area),
                unit_converter.area_val(this.state.numeric_codes.type, totals.W.area),
                unit_converter.area_val(this.state.numeric_codes.type, totals.E.area),
                unit_converter.area_val(this.state.numeric_codes.type, totals.N.area),
            ],
            yAxis: 0,
        };
        series.areaGlazed = {
            name: 'Area Glazed',
            type: 'spline',
            color: Highcharts.getOptions().colors[2],
            tooltip: {
                valueSuffix: ` %`
            },
            data: [
                totals.S.areaGlazedRatio,
                totals.W.areaGlazedRatio,
                totals.E.areaGlazedRatio,
                totals.N.areaGlazedRatio,
            ],
            yAxis: 1,
        };
        series.daylighting = {
            name: 'Daylighting',
            type: 'spline',
            color: Highcharts.getOptions().colors[3],
            tooltip: {
                valueSuffix: ` %`
            },
            data: [
                totals.S.daylightingAve,
                totals.W.daylightingAve,
                totals.E.daylightingAve,
                totals.N.daylightingAve,
            ],
            yAxis: 2,
        };
        series.heatGainAve = {
            name: 'Heat-Gain',
            type: 'spline',
            color: Highcharts.getOptions().colors[4],
            tooltip: {
                valueSuffix: ` ${this.state.numeric_codes.hg_unit}`
            },
            data: [
                unit_converter.hg_val(this.state.numeric_codes.type, totals.S.heatGainAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.W.heatGainAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.E.heatGainAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.N.heatGainAve),
            ],
            yAxis: 3,
        };
        series.solarLoadAve = {
            name: 'Solar-Load',
            type: 'spline',
            color: Highcharts.getOptions().colors[5],
            tooltip: {
                valueSuffix: ` ${this.state.numeric_codes.hg_unit}`
            },
            data: [
                unit_converter.hg_val(this.state.numeric_codes.type, totals.S.solarLoadAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.W.solarLoadAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.E.solarLoadAve),
                unit_converter.hg_val(this.state.numeric_codes.type, totals.N.solarLoadAve),
            ],
            yAxis: 4,
        };
        let answer = {
            title: {
                text: null,
                margin: 0
            },
            credits: {
                enabled: false
            },
            xAxis: [{
                categories: ['South', 'West', 'East', 'North'],
                crosshair: true
            }],
            yAxis: [
                {
                    min: 0,
                    max: null,
                    gridLineWidth: 1,
                    alignTicks: false,
                    //tickInterval: 1,
                    title: {
                        text: `Area (${this.state.numeric_codes.area_unit})`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    labels: {
                        format: `{value}`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                }, {
                    min: 0,
                    max: 100,
                    gridLineWidth: 0,
                    alignTicks: false,
                    //tickInterval: 1,
                    title: {
                        text: `Glazed (% of Area)`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },

                    },
                    opposite: false,
                }, {
                    min: 0,
                    max: 100,
                    gridLineWidth: 0,
                    alignTicks: false,
                    //tickInterval: 1,
                    title: {
                        text: 'Daylighting (%)',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    opposite: true,
                }, {
                    min: 0,
                    max: null,
                    gridLineWidth: 0,
                    alignTicks: false,
                    //tickInterval: 1,
                    title: {
                        text: `Heat-Gain (${this.state.numeric_codes.hg_unit})`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    labels: {
                        format: `{value}`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    opposite: true,
                }, {
                    min: 0,
                    max: null,
                    gridLineWidth: 0,
                    alignTicks: false,
                    //tickInterval: 1,                    
                    title: {
                        text: `Solar-Load (${this.state.numeric_codes.hg_unit})`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: `{value}`,
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    opposite: true,
                }],
            pane: {
                size: '100%'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //margin: 50,
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                headerFormat: '<h4>{point.key}</h4><table>',
                footerFormat: '</table>',
                useHTML: true
            },
            series: [series.area, series.areaGlazed, series.daylighting, series.heatGainAve, series.solarLoadAve],
            plotOptions: {
                spline: {
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    marker: {
                        enabled: true
                    },
                }
            }
        }
        return answer;
    }

    buildSolarRadiationChart(data) {
        let solarload_per_orientation = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ];

        let orientation_map = {
            "N": 0,
            "NNE": 1,
            "NE": 2,
            "ENE": 3,
            "E": 4,
            "ESE": 5,
            "SE": 6,
            "SSE": 7,
            "S": 8,
            "SSW": 9,
            "SW": 10,
            "WSW": 11,
            "W": 12,
            "WNW": 13,
            "NW": 14,
            "NNW": 15
        }
        data.map(s => {
            solarload_per_orientation[0][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[0].toFixed(2));
            solarload_per_orientation[1][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[1].toFixed(2));
            solarload_per_orientation[2][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[2].toFixed(2));
            solarload_per_orientation[3][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[3].toFixed(2));
            solarload_per_orientation[4][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[4].toFixed(2));
            solarload_per_orientation[5][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[5].toFixed(2));
            solarload_per_orientation[6][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[6].toFixed(2));
            solarload_per_orientation[7][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[7].toFixed(2));
            solarload_per_orientation[8][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[8].toFixed(2));
            solarload_per_orientation[9][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[9].toFixed(2));
            solarload_per_orientation[10][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[10].toFixed(2));
            solarload_per_orientation[11][orientation_map[s.orientation]] = parseFloat(s.solarLoadPerOrientation[11].toFixed(2));
            return null;
        });

        let answer = {
            chart: {
                type: 'column',
                polar: true,
                marginTop: 65,
                marginBottom: 100,
                //width: 400,
                height: 687,
                //plotAreaWidth: 600,
                //plotAreaHeight: 600
                //backgroundColor: 'white',
            },
            // exporting: this.defaultExporting,
            title: {
                text: 'Direct Solar Radiations by Orientation and Month',
                margin: 0
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: ['North', 'N-NE', 'NE', 'E-NE', 'East', 'E-SE', 'SE', 'S-SE', 'South', 'S-SW', 'SW', 'W-SW', 'West', 'W-NW', 'NW', 'N-NW'],
                tickmarkPlacement: 'on'
            },
            yAxis: {
                min: 0,
                // max: 350,
                endOnTick: false,
                showLastLabel: true,
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                reversedStacks: false,
                plotBands: [{
                    color: {
                        radialGradient: {
                            cx: 0.5,
                            cy: 0.5,
                            r: 0.5
                        },
                        stops: [
                            [0, 'rgba(50,50,255, .85)'],
                            [0.25, 'rgba(165,254,255, .85)'],
                            [0.4, 'rgba(255,255,255, .85)'],
                            [0.6, 'rgba(255,255,255, .85)'],
                            [0.8, 'rgba(255,255,50, .85)'],
                            [1, 'rgba(255,50,50, .85)']
                        ]
                    },
                    from: 0,
                    to: 350
                }],
            },
            pane: {
                size: '100%'
            },
            legend: {
                align: 'bottom',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //margin: 50,
            },
            tooltip: {
                headerFormat: '<h4>{point.key}</h4><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            series: [{
                name: 'January',
                data: solarload_per_orientation[0],
            }, {
                name: 'February',
                data: solarload_per_orientation[1],
            }, {
                name: 'March',
                data: solarload_per_orientation[2],
            }, {
                name: 'April',
                data: solarload_per_orientation[3],
            }, {
                name: 'May',
                data: solarload_per_orientation[4],
            }, {
                name: 'June',
                data: solarload_per_orientation[5],
            }, {
                name: 'July',
                data: solarload_per_orientation[6],
            }, {
                name: 'August',
                data: solarload_per_orientation[7],
            }, {
                name: 'September',
                data: solarload_per_orientation[8],
            }, {
                name: 'October',
                data: solarload_per_orientation[9],
            }, {
                name: 'November',
                data: solarload_per_orientation[10],
            }, {
                name: 'December',
                data: solarload_per_orientation[11],
            }],
            plotOptions: {
                series: {
                    shadow: false,
                    groupPadding: 0,
                    pointPlacement: 'on',
                    /*
                    events: {
                        legendItemClick: function(event) {
    
                        if (!this.visible)
                            return true;
    
                        let seriesIndex = this.index;
                        series = this.chart.series;
    
                        for (var i = 0; i < series.length; i++)
                        {
                            if (series[i].index != seriesIndex)
                            {
    
                                series[i].visible ? series[i].hide() : series[i].show();
                            }
                        }
    
                        return false;
                        }
                    }
                    */

                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'right',
                        verticalAlign: 'top'
                    }
                }
            },
        }
        return answer;
    }

    buildHeatGainChart(data) {
        let series = data.map((surface, index) => {
            // per surface...
            let result = {};
            result.name = surface.name;
            /*
            const reducer = (previousValue, currentValue) => previousValue + currentValue;
            let array = surface.heatGain;
            let sum = array.reduce(reducer);
            let ave = Number((sum / array.length).toFixed(2));
            result.y = unit_converter.hg_val(this.state.numeric_codes.type, ave);
            */
            result.y = unit_converter.hg_val(this.state.numeric_codes.type, surface.heatGainAve);
            return result;
        });
        //console.log(series);
        let answer = {
            chart: {
                type: 'column',
                polar: false,
                /*
                marginTop: 65,
                marginBottom: 100,
                height: 687,
                */
            },
            title: {
                text: null,
                margin: 0
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: {
                    text: `Ave Heat - Gain: ${this.state.numeric_codes.hg_unit} `
                },
                min: 0,
                max: 400,

            },
            pane: {
                size: '100%'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //margin: 50,
            },
            tooltip: {
                headerFormat: '<h4>{point.key}</h4><table>',
                pointFormat: `<td style="padding:0"><b>{point.y:.1f} ${this.state.numeric_codes.hg_unit}</b></td>`,
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            series: [
                {
                    name: "Surface",
                    data: series,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, 'red'],
                            [1, 'blue']
                        ]
                    }
                }
            ],
            plotOptions: {
                series: {
                    shadow: false,
                    groupPadding: 0,
                    pointPlacement: 'on',
                }
            }
        }
        return answer;
    }

    buildDaylightingChart(data) {
        let series = data.map(surface => {
            // per surface...
            let result = {};
            result.name = surface.name;
            result.y = surface.daylighting;
            //console.log()
            return result;
        });
        let answer = {
            chart: {
                type: 'column',
                polar: false,
                /*
                marginTop: 65,
                marginBottom: 100,
                height: 687,
                */
            },
            title: {
                text: null,
                margin: 0
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: {
                    text: `% Daylighting`
                },
                min: 0,
                max: 100,

            },
            pane: {
                size: '100%'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //margin: 50,
            },
            tooltip: {
                headerFormat: '<h4>{point.key}</h4><table>',
                pointFormat: `<td style="padding:0"><b>{point.y:.1f} %</b></td>`,
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            series: [
                {
                    name: "Surface",
                    data: series,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, 'yellow'],
                            [1, 'brown']
                        ]
                    }
                }
            ],
            plotOptions: {
                series: {
                    shadow: false,
                    groupPadding: 0,
                    pointPlacement: 'on',
                }
            }
        }
        return answer;
    }

    buildOverlayChart(data) {
        let series1 = {
            name: 'HeatGain',
            type: 'spline',
            color: Highcharts.getOptions().colors[4],
            tooltip: {
                valueSuffix: ` ${this.state.numeric_codes.hg_unit} `
            },
            data: data.map(surface => {
                return surface.heatGainAve;
            }),
            yAxis: 0,
        };
        let series2 = {
            name: 'Daylighting',
            type: 'spline',
            color: Highcharts.getOptions().colors[3],
            marker: {
                enabled: false
            },
            tooltip: {
                valueSuffix: ' %'
            },
            data: data.map(surface => {
                return surface.daylighting;
            }),
            yAxis: 1,
        };
        let answer = {
            chart: {
                type: 'spline',
                /*
                marginTop: 65,
                marginBottom: 100,
                height: 687,
                */
            },
            title: {
                text: null,
                margin: 0
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: data.map(entry => { return entry.name }),
                crosshair: true,
            },
            yAxis: [{ // Primary yAxis
                title: {
                    text: `Ave Heat - Gain: ${this.state.numeric_codes.hg_unit} `,
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                },
                labels: {
                    format: `{ value } ${this.state.numeric_codes.hg_unit} `,
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                },
            }, { // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: 'Daylighting %',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                labels: {
                    format: '{value} %',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                },
                opposite: true,
                min: 0,
                max: 100
            }],
            pane: {
                size: '100%'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //margin: 50,
            },
            tooltip: {
                shared: true,
                headerFormat: '<h4>{point.key}</h4><table>',
                footerFormat: '</table>',
                useHTML: true
            },
            series: [series1, series2],
            plotOptions: {
                spline: {
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    //pointInterval: 3600000,
                    //pointStart: Date.UTC(2018, 1, 13, 0, 0, 0)
                }
            }
        }
        return answer;
    }

    concatString(array) {
        let concat = "";
        array.forEach(item => {
            if (concat.length === 0) {
                concat += `${item} `;
            } else {
                concat += `, ${item} `;
            }
        })
        return concat;
    }

    buildGlazingsTable() {
        return this.state.glazings
            .map(glazing => {
                //console.log(glazing);
                let vendor_logo;
                switch (glazing.source) {
                    case "guardian": vendor_logo = GuardianLincIcon; break;
                    case "vitro": vendor_logo = VitroLincIcon; break;
                    case "viracon": vendor_logo = ViraconLincIcon; break;
                    default: vendor_logo = FenestraProLincIcon; break;
                }
                return (
                    <tr key={glazing.id}>
                        <td style={{ textAlign: "center" }}><img className='vendor-logo' src={vendor_logo} alt="vendor logo" /></td>
                        <td>{glazing.description}</td>
                        <td>{glazing.uv}</td>
                        <td>{glazing.shgc}</td>
                        <td>{glazing.vlt}</td>
                        <td style={{ wordWrap: 'breakWord' }}>{this.concatString(glazing.onFacades)}</td>
                        <td>{this.concatString(glazing.onSurfaces)}</td>
                        <td>{glazing.calculation_standard}</td>
                        <td>{glazing.program_version}</td>
                    </tr>
                );
            });
    }

    buildGlazingTableData() {
        return this.state.glazings
            .map((glazing, index) => {
                //console.log(glazing);
                let vendor_logo;
                switch (glazing.source) {
                    case "guardian": vendor_logo = GuardianLincIcon; break;
                    case "vitro": vendor_logo = VitroLincIcon; break;
                    case "viracon": vendor_logo = ViraconLincIcon; break;
                    default: vendor_logo = FenestraProLincIcon; break;
                }
                //console.log(vendor_logo);
                return (
                    {
                        id: index,
                        img: vendor_logo,
                        vendor: glazing.source,
                        desc: glazing.description,
                        uv: glazing.uv,
                        shgc: glazing.shgc,
                        vlt: glazing.vlt,
                        facade: glazing.onFacades.join(),
                        surfaces: glazing.onSurfaces.join(),
                        calc_standard: glazing.calculation_standard,
                        program_ver: glazing.program_version
                    }
                );
            });
    }

    buildSummaryTableData(report) {
        let email = report.user ? report.user.email : "unknown"
        let data =
            [{
                id: 1,
                item: 'File Name',
                info: report.file_name
            }, {
                id: 2,
                item: 'File Type',
                info: report.file_engine
            }, {
                id: 3,
                item: 'Project Name',
                info: report.project_details.project_name
            }, {
                id: 4,
                item: 'Project Address',
                info: report.project_details.project_address
            }, {
                id: 5,
                item: 'Project Number',
                info: report.project_details.project_number
            }, {
                id: 6,
                item: 'Project Status',
                info: report.project_details.project_status
            }, {
                id: 7,
                item: 'Project Issue Date',
                info: report.project_details.project_issue_date
            }, {
                id: 8,
                item: 'Client Name',
                info: report.project_details.client_name
            }, {
                id: 9,
                item: 'Building Name',
                info: report.project_details.building_name
            }, {
                id: 10,
                item: 'Building Use',
                info: report.building_manager.building_use
            }, {
                id: 11,
                item: 'Maintenance Factor',
                info: report.building_manager.location_settings
            }, {
                id: 12,
                item: 'Unit of Measurement',
                info: report.project_details.is_metric ? 'metric' : 'imperial'
            }, {
                id: 13,
                item: 'Longitude',
                info: Number((report.building_manager.lng).toFixed(2))
            }, {
                id: 14,
                item: 'Latitude',
                info: Number((report.building_manager.lat).toFixed(2))
            }, {
                id: 15,
                item: 'Organization',
                info: report.organization.name
            }, {
                id: 16,
                item: 'User',
                info: email
            }, {
                id: 17,
                item: 'Date',
                info: (report.date).substring(0, 10)
            }];
        return data
    }
}

Report.contextType = AppContext;
export default Report;