import { Component } from 'react';
//import { Link } from "react-router-dom";
import AppContext from '../AppContext';
import API from '../Api';
import Reviticon from "../img/revit_icon.png";
import GearIcon from "../img/gear_icon.png";
import Modal from 'react-modal';
import ShareOptions from "./ShareOptions";
import Tile from "./Tile";
//import { resolveProjectReferencePath } from 'typescript';

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            groups: [],
            search: "",
            fetching: true,
            displayModal: false,
            modalReportData: null,
            modalMode: 'options',
            grouped: true,
            noContentMsg: "none",
        }
    }

    componentDidMount() {
        Modal.setAppElement('body');
        API.getReports()
            .then(reports => {
                //console.log(reports);
                if (reports.length > 0) {
                    // check user.object
                    reports.forEach(report => {
                        if (!report.user) {
                            report.user = {};
                            report.user.username = 'unknown';
                            report.user.email = 'unknown';
                            report.user._id = 'unknown';
                            report.user.id = 'unknown';
                        }
                    })
                    //
                    let groupedReports = this.state.groups;
                    reports.forEach(report => {
                        if (groupedReports.length === 0) {
                            groupedReports.push({ id: report.id, name: report.file_name, count: 1 })
                        } else {
                            let index = 0;
                            while (index < groupedReports.length) {
                                if (groupedReports[index].name === report.file_name) {
                                    groupedReports[index].count++;
                                    break;
                                } else if (index === groupedReports.length - 1) {
                                    groupedReports.push({ id: report.id, name: report.file_name, count: 1 });
                                    break;
                                } else {
                                    index++;
                                }
                            };
                        }
                    })
                    //console.log(groupedReports);
                    this.setState({
                        reports: reports,
                        groups: groupedReports,
                        fetching: false
                    });
                } else {
                    this.setState({ noContentMsg: "block" });
                }

            })
            .catch(error => {
                console.warn("Error");
            });
    }

    render() {
        return (
            <div id="reports-page">
                <h1 className="page-title">REPORTS</h1>
                <div id="reports-container-tiles">
                    <div id="search-container">
                        Search:
                        <input id="search" value={this.state.search} onChange={(e) => this.searchTerm(e.target.value)} />
                        {(() => {
                            if (this.state.grouped === false) {
                                return (
                                    <button className='btn btn-sm btn-primary' id='back-to-groups' onClick={(e) => this.setState({ grouped: true, search: "" })}>Goto: Grouped View</button>
                                )
                            } else {
                                return (
                                    <button className='btn btn-sm btn-primary' id='back-to-list' onClick={(e) => this.setState({ grouped: false, search: "" })}>Goto: List View</button>
                                )
                            }
                        })()}
                    </div>
                    <div id="empty-message" style={{ display: this.state.noContentMsg }}>
                        <span>No projects to display.</span>
                    </div>
                    <div id="tiles">
                        {(() => {
                            if (this.state.grouped === true) {
                                return (
                                    this.groupReports()
                                )
                            } else {
                                return (
                                    this.listReports()
                                )
                            }
                        }

                        )()
                        }
                    </div>
                </div>
                {(() => {
                    if (this.state.displayModal === true) {
                        return (
                            <Modal isOpen={true}
                                onRequestClose={(e) => this.showDisplayModal(e, false, null)}
                                contentLabel="Example Modal"
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        background: 'rgba(0,0,0,.5)'
                                    },
                                    content: {
                                        width: "600px",
                                        maxHeight: "80%",
                                        position: "relative",
                                        top: "50%",
                                        padding: "16px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        transform: "translateY(-50%)",
                                        transition: "opacity 2s"
                                    }
                                }}
                            >
                                <div id='project-modal-content'>
                                    <div className="fpe-file-name-modal">
                                        <div>Project: {this.state.modalReportData.file_name}</div>
                                        <div>Report Id: {this.state.modalReportData.id}</div>
                                    </div>
                                </div>
                                <div id='project-modal-mode-container'>
                                    {(() => {
                                        if (this.state.modalMode === "options") {
                                            return (
                                                <div>
                                                    <button className="theme-btn" onClick={e => this.setState({ modalMode: "share" })} disabled={this.state.submitting}>Share Report</button>
                                                    <button className="theme-btn delete-btn" onClick={e => this.deleteReport()} disabled={this.state.submitting}>Delete Report</button>
                                                </div>
                                            )
                                        }
                                        else if (this.state.modalMode === "share") {
                                            return (
                                                <div>
                                                    <ShareOptions share_item="reports" item_id={this.state.modalReportData._id} />
                                                    <button className="go-back" onClick={() => this.setState({ modalMode: "options" })}>« Go Back</button>
                                                </div>
                                            )
                                        }

                                    })()}
                                </div>
                            </Modal>
                        )
                    }
                })()}
            </div>
        )
    }

    listReports() {
        return this.state.reports
            .filter(report => {
                if (this.state.search === "") {
                    return true;
                }
                else {
                    return report.file_name.toLowerCase().includes(this.state.search);
                }
            })
            .map(report => {
                //console.log(report)
                let email;
                if (report.user) {
                    email = report.user.email
                } else {
                    email = 'unknown';
                }
                return (
                    <Tile
                        key={report.id}
                        tileClick={this.openReport}
                        tileClickData={report.id}
                        title={`Project: ${report.file_name.split(".")[0]}`}
                        subtitle={`Report: ${report.id}`}
                        creator={`Author: ${email}`}
                        created={report.date}
                        logoIcon={Reviticon}
                        settingsIcon={GearIcon}
                        settingsClick={this.showDisplayModal}
                        settingsData={{
                            data: report,
                            status: true
                        }
                        }
                    />
                );
            });
    }

    groupReports() {
        return this.state.groups
            .filter(group => {
                if (this.state.search === "") {
                    return true;
                }
                else {
                    return group.name.toLowerCase().includes(this.state.search);
                }
            })
            .map(group => {
                return (
                    <Tile
                        key={group.id}
                        tileClick={this.revealGroup}
                        tileClickData={group.name}
                        title={`Project: ${group.name.split(".")[0]}`}
                        subtitle={`No of Reports: ${group.count}`}
                        logoIcon={Reviticon}
                    />

                );
            });
    }

    deleteReport() {
        let id = this.state.modalReportData._id;
        if (window.confirm("Confirm that you want to delete this project.")) {
            this.setState({ submitting: true });
            API.deleteReportByFileId(id)
                .then(response => {
                    /*
                    this.setState({
                        projects: this.state.projects.filter(p => p._id !== id),
                        project_detail: null,
                        project_modal_mode: "options"
                    });
                    */
                    console.log(`This page will refresh automatically in a few seconds`);
                    setTimeout(() => {
                        window.location = "/reports";
                    }, 3000);
                })
                .catch(error => {
                    console.log(error);
                })
                .always(() => {
                    this.setState({ submitting: false });
                });
        }
    }

    revealGroup = (e, group) => {
        //e.stopPropagation();
        this.setState({ grouped: false }, () => {
            this.searchTerm(group);
        });
    }

    openReport = (e, reportId) => {
        e.stopPropagation();
        console.log(`/reports/${reportId}`);

        window.location = (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || 'http://localhost:8080') + "/reports/" + reportId

        //window.location = `/reports/${reportId}`
        //window.location = `/report/${reportId}`

        /*
        return (
            <Link to={`/reports/${reportId}`}></Link>
        )
        */
                
        /*API.getReportDataById(reportId)
            .then(response => {
                console.log('responded')
                return (
                    <Link to={`/reports/${reportId}`}></Link>
                )
            })
            .catch(error => {
                console.log(error);
            })
            .always(() => {
                this.setState({ submitting: false });
            });
        */
    }

    showDisplayModal = (e, data) => {
        e.stopPropagation();
        this.setState({
            modalReportData: data.data,
            displayModal: data.status,
            modalMode: 'options'
        });
    }

    searchTerm(term) {
        this.setState({ search: term.toLowerCase().trim() });
    }
}

Reports.contextType = AppContext;
export default Reports;