import React from 'react';
import AppContext from '../AppContext';
const ThemeFenestraPro = React.lazy(() => import('./ThemeFenestraPro'));
const ThemeGuardian = React.lazy(() => import('./ThemeGuardian'));

class Theme extends React.Component{
    render(){
        return (
            <AppContext.Consumer>
                 {context => (
                    <React.Suspense fallback={<></>}>
                        {(context.user.organization_details.platform_type === "fenestrapro") && <ThemeFenestraPro />}
                        {(context.user.platform_type === "guardian") && <ThemeGuardian />}
                    </React.Suspense>
                )}
            </AppContext.Consumer>
        );
    }
}


export default Theme;