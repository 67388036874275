import { Component } from 'react';
import * as stringSimilarity from "string-similarity";
import AppContext from '../AppContext';
import API from '../Api';
import { EMAIL_REGEX } from "../Regex";

const available_regions = ["North America","South America","Europe","Africa", "Middle East", "Asia", "India"];

class GlassEnquiry extends Component {
    
    constructor(props){
        super(props);
        
        let glass_regions = this.props.glass_data.regions || "";
        let highest_similarity = {
            region: "",
            score: 0
        }

        if(glass_regions!==""){
            glass_regions.forEach(gr => {
                available_regions.forEach(ar => {
                    let score = stringSimilarity.compareTwoStrings(ar, gr);
                    if(score > 0 && score > highest_similarity.score){
                        highest_similarity = {
                            region: ar,
                            score: score
                        } 
                    }
                });
            });
        }
        
        this.state = {
            region: highest_similarity.region,
            full_name: "",
            email: "",
            practice_name: "",
            question: "",
            submitting: false,
            enquiry_result: ""
        }
    }   
    componentWillMount(){

        this.setState({
            email: this.context.user.email,
            full_name: this.context.user.firstname + " " + this.context.user.lastname
        })
    }
    render(){
        if(this.state.enquiry_result === ""){
            return (
                <table id='glass-enquiry-form'>
                    <tbody>
                        <tr>
                            <td>
                                Region <span className="required-symbol" title='Required Field'>*</span>
                            </td>
                            <td>
                                <select  className="theme-input" value={this.state.region} onChange={e => this.setState({region: e.target.value})}>
                                    <option value="">Select a region</option>
                                    {available_regions.map(region =>  <option key={region} value={region}>{region}</option>)}
                                </select>  
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Full Name <span className="required-symbol" title='Required Field'>*</span>
                            </td>
                            <td>
                                <input  className="theme-input" type="text"  value={this.state.full_name} onChange={e => this.setState({full_name: e.target.value})} placeholder="Enter your full name here"></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Email <span className="required-symbol" title='Required Field'>*</span>
                            </td>
                            <td>
                                <input  className="theme-input" type="text"  value={this.state.email} onChange={e => this.setState({email: e.target.value})} placeholder="Enter your email here"></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Practice Name <span className="required-symbol" title='Required Field'>*</span>
                            </td>
                            <td>
                                <input  className="theme-input" type="text"  value={this.state.practice_name} onChange={e => this.setState({practice_name: e.target.value})} placeholder="Enter your practice name here"></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Enquiry Text
                            </td>
                            <td>
                                <textarea className="theme-input" value={this.state.question}  onChange={e => this.setState({question: e.target.value})} placeholder="Enter your enquiry here"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            
                            </td>
                            <td>
                                <button className="theme-btn full-width" disabled={
                                    this.state.submitting ||
                                    this.state.region === "" ||
                                    !EMAIL_REGEX.test(this.state.email) ||
                                    this.state.full_name.trim().length === 0
                                } onClick={() => this.sendEnquiry()}>Send Enquiry</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }
        else if(this.state.enquiry_result === "success"){
            return (
                <>
                    <div className="enquiry-result-success">✔</div>
                    <h2>Enquiry submitted!</h2>
                </> 
            )
        }
        else if(this.state.enquiry_result === "error"){
            return (
                <>
                    <div className="enquiry-result-fail">✘</div>
                    <h2>Enquiry submission failed</h2>
                </>
            )
        }
    }

    sendEnquiry(){
        this.setState({submitting: true});
        let obj = { 
            ...this.props.glass_data, 
            ...this.state, 
            vendor_name: this.props.glass_data.source,
            glass_description: this.props.glass_data.description
        };
        API.sendGlassEnquiry(obj)
        .then((response) => {
            this.setState({
                submitting: false,
                enquiry_result: "success"
            });
        })
        .catch(error => {
            this.setState({
                submitting: false,
                enquiry_result: "error"
            });
        });
    }
}

GlassEnquiry.contextType = AppContext;
export default GlassEnquiry;