import { createContext } from "react";

const AppContext =  createContext({
    user:{
        username: "",
        firstname: "",
        lastname: "",
        platform_type: "",
        id: "",
        _id: "",
        email: "",
        teams: [],
        is_main: false
    },
    colleagues: [],
    settings:{
        numeric_system: "default",
        report_templates: [],
        custom_charts: [],
        custom_performance_standards: [],
        shared_reports: [],
        shared_projects: [],
        shared_custom_glazings:  [],
        custom_glazing: [],
        notifications: {
            email_shared_reports: true,
            email_shared_projects: true,
            email_shared_glazings: true,
        }
    },
});

export default AppContext;