import Cookies from 'js-cookie';
import $ from 'jquery';
const BASE_URL = process.env.REACT_APP_API_URL || process.env.API_URL || 'http://localhost:4200/v0';
let AUTH_TOKEN = Cookies.get('token') || null;

const API = {

    userSignin: function (email, password, rememberme) {
        return $.ajax({
            url: `${BASE_URL}/auth/user/signin`,
            method: 'POST',
            data: JSON.stringify({ email: email, password: password, rememberme: rememberme }),
            dataType: 'json',
            contentType: 'application/json'
        });
    },

    tokenSignin: function (token) {
        AUTH_TOKEN = token;
        console.log(AUTH_TOKEN)
        return $.ajax({
            url: `${BASE_URL}/auth/user/token_signin`,
            method: 'POST',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    verifyUser: function (user_id, email, password, confirm_password) {
        return $.ajax({
            url: `${BASE_URL}/auth/user/verify`,
            method: 'POST',
            data: { user_id: user_id, email: email, password: password, confirm_password: confirm_password }
        });
    },

    forgotPassword: function (email) {
        return $.ajax({
            url: `${BASE_URL}/auth/forgot_password`,
            method: 'POST',
            data: { email: email },
        });
    },

    accountRecovery: function (enc, new_password, confirm_password) {
        return $.ajax({
            url: `${BASE_URL}/auth/account_recovery`,
            method: 'POST',
            data: { enc: enc, new_password: new_password, confirm_password: confirm_password }
        });
    },

    inviteFirstAdminUser: function (enc, administrator_email, confirm_administrator_email) {
        return $.ajax({
            url: `${BASE_URL}/auth/invite_first_admin_user`,
            method: 'POST',
            data: {
                enc: enc,
                administrator_email: administrator_email,
                confirm_administrator_email: confirm_administrator_email
            }
        });
    },

    addFirstAdminUser: function (enc, email, password, confirm_password) {
        return $.ajax({
            url: `${BASE_URL}/auth/create_first_admin_user`,
            method: 'POST',
            data: {
                enc: enc,
                email: email,
                password: password,
                confirm_password: confirm_password
            }
        });
    },

    getFenestraProEnquiry: function (data) {
        return $.ajax({
            url: `${BASE_URL}/getfenestrapro`,
            method: 'POST',
            data: data
        });
    },

    getOrganizationDetails: function () {
        console.log(AUTH_TOKEN)
        return $.ajax({
            url: `${BASE_URL}/organization/details`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getUsedStorageSize: function () {
        return $.ajax({
            url: `${BASE_URL}/organization/used_storage`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getUsers: function () {
        return $.ajax({
            url: `${BASE_URL}/organization/users`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getColleagues: function () {
        console.log(AUTH_TOKEN)
        return $.ajax({
            url: `${BASE_URL}/organization/colleagues`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getTeams: function () {
        return $.ajax({
            url: `${BASE_URL}/organization/teams`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getMachines: function () {
        return $.ajax({
            url: `${BASE_URL}/organization/machines`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    editMachine: function (machine_id, friendly_name) {
        return $.ajax({
            url: `${BASE_URL}/organization/edit_machine`,
            method: "POST",
            dataType: "json",
            data: { machine_id: machine_id, friendly_name: friendly_name },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteMachine: function (machine_id) {
        return $.ajax({
            url: `${BASE_URL}/organization/machines`,
            method: "DELETE",
            dataType: "json",
            data: { machine_id: machine_id },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteTeam: function (team_id) {
        return $.ajax({
            url: `${BASE_URL}/organization/teams`,
            method: "DELETE",
            data: { team_id: team_id },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    addUser: function (email, confirm_email, firstname = "", lastname = "", username = "", is_main = false, teams = []) {
        return $.ajax({
            url: `${BASE_URL}/organization/users`,
            method: "POST",
            dataType: "json",
            data: {
                email: email,
                confirm_email: confirm_email,
                firstname: firstname,
                lastname: lastname,
                username: username,
                is_main: is_main,
                teams: teams
            },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    editUser: function (user_id, firstname, lastname, username, is_main, teams = []) {
        return $.ajax({
            url: `${BASE_URL}/organization/edit_user`,
            method: "POST",
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ user_id: user_id, firstname: firstname, lastname: lastname, username: username, is_main: is_main, teams: teams }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    removeTeamFromUser: function (user_id, team) {
        return $.ajax({
            url: `${BASE_URL}/organization/remove_team_from_user`,
            method: "POST",
            data: { user_id: user_id, teams: team },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    sendInvite: function (user_id) {
        return $.ajax({
            url: `${BASE_URL}/organization/invite_user`,
            method: "POST",
            data: { user_id: user_id },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteUser: function (user_id) {
        return $.ajax({
            url: `${BASE_URL}/organization/users`,
            method: "DELETE",
            dataType: "json",
            data: { user_id: user_id },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    addTeam: function (name, users_ids) {
        return $.ajax({
            url: `${BASE_URL}/organization/teams`,
            method: "POST",
            data: { name: name, users_ids: users_ids },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    editTeam: function (team_id, team_name, users) {
        return $.ajax({
            url: `${BASE_URL}/organization/edit_team`,
            method: "POST",
            data: { team_id: team_id, team_name: team_name, users: users },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getProfileInfo: function () {
        console.log(AUTH_TOKEN)
        return $.ajax({
            url: `${BASE_URL}/user/profile`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },


    editProfileInfo: function (firstname, lastname, username) {
        console.log(`${firstname}, ${lastname}, ${username}`)
        return $.ajax({
            url: `${BASE_URL}/user/profile`,
            method: 'POST',
            data: {
                firstname: firstname,
                lastname: lastname,
                username: username
            },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getUserSettings: function () {
        console.log(AUTH_TOKEN)
        return $.ajax({
            url: `${BASE_URL}/user/settings`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getNumericSystem: function () {
        return $.ajax({
            url: `${BASE_URL}/user/settings/numericsystem`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    editAppPreferences: function (numeric_system, email_notification_shared_codes, email_notification_shared_reports, email_notification_shared_projects, email_notification_shared_glazings) {
        return $.ajax({
            url: `${BASE_URL}/user/settings/app_preferences`,
            method: 'POST',
            data: {
                numeric_system: numeric_system,
                email_notification_shared_codes: email_notification_shared_codes,
                email_notification_shared_reports: email_notification_shared_reports,
                email_notification_shared_projects: email_notification_shared_projects,
                email_notification_shared_glazings: email_notification_shared_glazings
            },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    changePassword: function (old_password, new_password) {
        return $.ajax({
            url: `${BASE_URL}/user/changepassword`,
            method: 'POST',
            data: { old_password: old_password, new_password: new_password },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getForgeToken: function () {
        return $.ajax({
            url: `${BASE_URL}/forge/token`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getForgeThumbnail: function (objectkey) {
        return $.ajax({
            url: `${BASE_URL}/forge/thumbnail?objectkey=${objectkey}`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getForgeManifest: function (urn) {
        return $.ajax({
            type: 'GET',
            url: `${BASE_URL}/forge/manifest`,
            data: { urn: urn },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    addForgeProject: function (formData) {
        return $.ajax({
            type: 'POST',
            url: `${BASE_URL}/forge/object`,
            data: formData,
            headers: { Authorization: AUTH_TOKEN },
            processData: false,
            contentType: false
        });
    },

    getProjects: function () {
        return $.ajax({
            url: `${BASE_URL}/projects/list`,
            type: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getFpeProjects: function () {
        return $.ajax({
            url: `${BASE_URL}/fpe_projects`,
            type: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getFpeThumbnail: function (id) {
        return $.ajax({
            url: `${BASE_URL}/fpe_thumbnails/${id}`,
            type: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteProject: function (project_id) {
        return $.ajax({
            url: `${BASE_URL}/projects/delete`,
            method: "POST",
            data: { project_id: project_id },
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteFpeProject: function (project_id) {
        return $.ajax({
            url: `${BASE_URL}/fpe_projects/${project_id}`,
            method: "DELETE",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getLincDataset: function () {
        return $.ajax({
            url: `${BASE_URL}/linc/glazing`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getLincGlasses: function (queryString = "") {
        return $.ajax({
            url: `${BASE_URL}/glazing/featured${queryString}`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getLincDefaultOptions: function () {
        return $.ajax({
            url: `${BASE_URL}/glazing/default_option_filters`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getGlassInformation: function (glass_id) {
        return $.ajax({
            method: "GET",
            url: `${BASE_URL}/glazing_info/${glass_id}`,
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    sendGlassEnquiry: function (payload) {
        return $.ajax({
            url: `${BASE_URL}/glazing/enquiry`,
            method: "POST",
            data: payload,
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteCustomGlassMakeup: function (glass_id) {
        return $.ajax({
            url: `${BASE_URL}/glazing/custom/${glass_id}`,
            method: 'DELETE',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    shareCustomMakeup: function (glass_id, target, share_type, notify = true) {
        console.log(this)
        return $.ajax({
            url: `${BASE_URL}/share/custom_glazing`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ glass_id: glass_id, target: target, share_type: share_type, notify: notify }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    shareProject: function (project_id, target, share_type, notify = true) {
        return $.ajax({
            url: `${BASE_URL}/share/project`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ project_id: project_id, target: target, share_type: share_type, notify: notify }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    shareFpeProject: function (fpe_project_id, target, share_type, notify = true) {
        return $.ajax({
            url: `${BASE_URL}/share/fpe_project`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ fpe_project_id: fpe_project_id, target: target, share_type: share_type, notify: notify }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    shareReport: function (report_id, target, share_type, notify = true) {
        return $.ajax({
            url: `${BASE_URL}/share/report`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ report_id: report_id, target: target, share_type: share_type, notify: notify }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    shareCode: function (code_id, target, share_type, notify = true) {
        return $.ajax({
            url: `${BASE_URL}/share/code`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ code_id: code_id, target: target, share_type: share_type, notify: notify }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getReports: function () {
        return $.ajax({
            url: `${BASE_URL}/reports`,
            method: "GET",
            contentType: "application/json",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getCodes: function () {
        return $.ajax({
            url: `${BASE_URL}/codes`,
            method: "GET",
            contentType: "application/json",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getReportDataById: function (report_id) {
        console.log(`about to request a report`)
        return $.ajax({
            url: `${BASE_URL}/report/${report_id}`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getShareNetwork: function () {
        return $.ajax({
            url: `${BASE_URL}/share/network`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteCodeById: function (id) {
        return $.ajax({
            url: `${BASE_URL}/code_delete/${id}`,
            method: "DELETE",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    updateCodeById: function (data) {
        return $.ajax({
            url: `${BASE_URL}/code_update`,
            method: "PUT",
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ data }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    createCode: function (data) {
        return $.ajax({
            url: `${BASE_URL}/code_create`,
            method: "POST",
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ data }),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    hideCodeById: function (id) {
        return $.ajax({
            url: `${BASE_URL}/code_hide/${id}`,
            method: "DELETE",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getProjectData: function (project_id) {
        return $.ajax({
            url: `${BASE_URL}/projects/${project_id}`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    saveReport: function (payload) {
        return $.ajax({
            url: `${BASE_URL}/reports`,
            method: "POST",
            data: JSON.stringify(payload),
            dataType: 'json',
            contentType: 'application/json',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getWebSnapshotByFileId: function (file_id) {
        return $.ajax({
            url: `${BASE_URL}/websnapshots/${file_id}`,
            method: "GET",
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getSurfaceSolarDataset: function (latitude, longitude, orientation, inclination, start_date, end_date, time_zone, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {

        return $.ajax({
            url: `${BASE_URL}/surfacesolardata`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                latitude: latitude,
                longitude: longitude,
                orientation: orientation,
                inclination: inclination,
                start_date: start_date,
                end_date: end_date,
                time_zone: time_zone,
                monday: monday,
                tuesday: tuesday,
                wednesday: wednesday,
                thursday: thursday,
                friday: friday,
                saturday: saturday,
                sunday: sunday
            }),
            headers: { Authorization: AUTH_TOKEN },
        });
    },

    calculateSurfaceValues: function (payload) {
        return $.ajax({
            url: `${BASE_URL}/surfacevalues`,
            method: "POST",
            data: JSON.stringify(payload),
            dataType: 'json',
            contentType: 'application/json',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    saveCustomReportsTemplate: function (template) {
        return $.ajax({
            url: `${BASE_URL}/user/settings/report_templates`,
            method: "POST",
            headers: { Authorization: AUTH_TOKEN },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(template),
        });
    },

    deleteReportByFileId: function (file_id) {
        return $.ajax({
            url: `${BASE_URL}/reports/${file_id}`,
            method: "DELETE",
            headers: { Authorization: AUTH_TOKEN },
        });
    },

    deleteCustomReportsTemplate: function (template_id) {
        return $.ajax({
            url: `${BASE_URL}/user/settings/report_templates/${template_id}`,
            method: "DELETE",
            headers: { Authorization: AUTH_TOKEN },
        });
    },

    getVideos: function () {
        return $.ajax({
            url: `${BASE_URL}/user/videos`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getTimeZone: function (latitude, longitude) {
        return $.ajax({
            url: `${BASE_URL}/geo/timezone/?lat=${latitude}&lng=${longitude}`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN },
        });
    },

    getClimateZoneByCoordinates: function (latitude, longitude) {
        return $.ajax({
            url: `${BASE_URL}/geo/climate_zone?lat=${latitude}&lng=${longitude}`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getSunPath: function (latitude, longitude) {
        return $.ajax({
            url: `${BASE_URL}/geo/solarpath?lat=${latitude}&lng=${longitude}`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getLearnUponSSO: function () {
        return $.ajax({
            url: `${BASE_URL}/user/learnupon_sso`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    getOrganizationsByVendorManager: function () {
        return $.ajax({
            url: `${BASE_URL}/vendor_management/organizations`,
            method: 'GET',
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    createVendorManagedLicense: function (organization_name, organization_region, user_1_firstname, user_1_lastname, user_1_email, user_2_firstname, user_2_lastname, user_2_email, custom_message) {
        return $.ajax({
            url: `${BASE_URL}/vendor_management/organization`,
            method: 'POST',
            headers: { Authorization: AUTH_TOKEN },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                organization_name: organization_name,
                organization_region: organization_region,
                user_1_firstname: user_1_firstname,
                user_1_lastname: user_1_lastname,
                user_1_email: user_1_email,
                user_2_firstname: user_2_firstname,
                user_2_lastname: user_2_lastname,
                user_2_email: user_2_email,
                custom_message: custom_message
            }),
        });
    },

    addUserToVendorManagedLicense: function (postData) {
        return $.ajax({
            url: `${BASE_URL}/vendor_management/user`,
            method: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(postData),
            headers: { Authorization: AUTH_TOKEN }
        });
    },

    deleteUserFromVendorManagedLicense: function (user_id, organization_id) {
        return $.ajax({
            url: `${BASE_URL}/vendor_management/user`,
            method: "DELETE",
            data: { user_id: user_id, organization_id: organization_id },
            headers: { Authorization: AUTH_TOKEN }
        })
    },

    getProjectFileFPE: function (id) {
        return $.ajax({
            url: `${BASE_URL}/fpe_projects/${id}`,
            method: "GET",
            dataType: 'text',                                   //solution code
            mimeType: 'text/plain; charset=x-user-defined',     //solution code
            headers: { Authorization: AUTH_TOKEN }
        });
    }
}

export default API;