import { Component } from 'react';
import '../styles/LoadingLayer.scss';

class Redirect extends Component {

    componentDidMount(){
        console.log(window.location.pathname)
        console.log(window.location.search)
        window.location =  (process.env.REACT_APP_PLATFORM_URL || process.env.PLATFORM_URL || 'http://localhost:8080') + window.location.pathname + window.location.search;
    }
    
    render(){
        return null
    }
}

export default Redirect;