import { Component } from 'react';
import AppContext from '../AppContext';
import { Link } from "react-router-dom";
import { openLearnUponPlatform } from '../Utils';
import ProjectsIcon from "../img/new-3d-building.png";
import ReportsIcon from "../img/new-analytics.png";
import CodesIcon from "../img/new-advanced-settings.png";
import ProfileIcon from "../img/user_icon.png";
import GlassDatabaseIcon from "../img/new-window-icon.png";
import LmsIcon from "../img/new-knowledge.png";
import InstructionsIcon from "../img/new-instructions.png";
import GuardianIcon from "../img/vendors/guardian_symbol_white_transparent.png";
import FenestraProIcon from "../img/vendors/new-f-icon.png";
import { GUARDIAN_EMAIL_REGEX } from '../Regex';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Home extends Component {
    
    render(){
        return (
            <div id="homepage" className='container-fluid'>
                <h1 id='welcome-home'>Welcome {this.context.user.firstname+" "+this.context.user.lastname}</h1>
                <div id="homepage-buttons-container" className='row'>
                    {(() => {
                        if(this.context.user.platform_type === "guardian"){
                            return(
                                <div>
                                    <div className='col-lg-3'>
                                        <Link className="hp-btn" to='/profile'>
                                            <img src={ProfileIcon} alt="Profile"/>
                                            <br/>
                                            Account Profile
                                        </Link>
                                    </div>
                                    <div className='col-lg-3'>
                                        <Link className="hp-btn" to='/reports'>
                                            <img src={ReportsIcon} alt="Reports"/>
                                            <br/>
                                            Reports
                                        </Link>
                                    </div>
                                    <div className='col-lg-3'>
                                        <Link className="hp-btn" to='/glassdatabase'>
                                            <img src={GlassDatabaseIcon} alt="Glass Database"/>
                                            <br/>
                                            Glass
                                        </Link> 
                                    </div>
                                    <div className='col-md-3'>
                                        <Link className="hp-btn" to="/codes">
                                            <img className='topbar-item-icon big-icon' src={CodesIcon} alt="Codes"/>
                                            <br/>
                                            Codes
                                        </Link>
                                    </div>

                                    <div className='col-md-12'>
                                        <div id='learnupon-sso-home' className="hp-btn" onClick={()=>openLearnUponPlatform()}>
                                            <span className='standout'>New</span>
                                            <img src={LmsIcon} alt="Learning"/>
                                            <br/>
                                            Learning Resources
                                        </div>
                                    </div>
                                    {
                                        GUARDIAN_EMAIL_REGEX.test(this.context.user.email) ? 
                                        <div className='col-md-12'>
                                            <Link className="hp-btn"  to='/vendor_management' >
                                                <img  src={GuardianIcon} alt="Guardian"/>
                                                <br/>
                                                Users & License Management
                                            </Link>
                                        </div>
                                        : 
                                        ""
                                    }
                                    
                                    <div className='col-md-12'>
                                        <div className="hp-btn" onClick={() => window.location ="https://guardian-support.fenestrapro.com/knowledgebase/1-5-installation/"}>
                                            <span className='standout'>New version!</span>
                                            <img src={GuardianIcon} alt="Guardian"/>
                                            <br/>
                                            Download Guardian-Glass-for-BIM
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="hp-btn" onClick={() => window.open("https://glassanalytics.guardian.com?From=FenestraPro", "_blank")}>
                                            <img src={GuardianIcon} alt="Guardian"/>
                                            <br/>
                                            Guardian Glass Analytics
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="hp-btn" onClick={() => window.open("https://guardian-support.fenestrapro.com", "_blank")}>
                                            <img src={GuardianIcon} alt="Guardian"/>
                                            <br/>
                                            <span className="hp-btn-text">Support</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="hp-btn" onClick={() => window.open("https://www.guardianglass.com/", "_blank")}>
                                            <img src={GuardianIcon} alt="Guardian"/>
                                            <br/>
                                            Guardian Website
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div>
                                    <div className='col-md-3'>
                                        <Link className="hp-btn"  to="/fpe_projects">
                                            <img className='topbar-item-icon big-icon' src={ProjectsIcon} alt="Projects"/>
                                            <br/>
                                            Projects
                                        </Link>
                                    </div>
                                    <div className='col-md-3'>
                                        <Link className="hp-btn" to="/reports">
                                            <img className='topbar-item-icon big-icon' src={ReportsIcon} alt="Reports"/>
                                            <br/>
                                            Reports
                                        </Link>
                                    </div>
                                    <div className='col-md-3'>
                                        <Link className="hp-btn" to="/glassdatabase">
                                            <img className='topbar-item-icon big-icon' src={GlassDatabaseIcon} alt="Glass Database"/>
                                            <br/>
                                            Glass
                                        </Link>
                                    </div>
                                    <div className='col-md-3'>
                                        <Link className="hp-btn" to="/codes">
                                            <img className='topbar-item-icon big-icon' src={CodesIcon} alt="Codes"/>
                                            <br/>
                                            Codes
                                        </Link>
                                    </div>
                                    
                                    <div className='col-md-12'>
                                        <div id='learnupon-sso-home' onClick={()=>openLearnUponPlatform()} className="hp-btn">
                                            <span className='standout'>New</span>
                                            <img   src={LmsIcon} alt="Learning"/>
                                            <br/>
                                            Learning Resources
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="hp-btn" onClick={() =>window.open("https://support.fenestrapro.com/knowledgebase/1-5-installation/","_blank")}>
                                            <span className='standout'>New version!</span>
                                            <img  src={FenestraProIcon} alt="FenestraPro"/>
                                            <br/>
                                            Download FenestraPro-for-Revit
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="hp-btn" onClick={() =>window.open("https://support.fenestrapro.com/", "_blank")}>
                                            <img className='topbar-item-icon' src={InstructionsIcon} alt="Instructions"/>
                                            <br/>
                                            Support
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="hp-btn"  onClick={() => window.open("https://fenestrapro.com/", "_blank")}>
                                            <img className='topbar-item-icon'src={FenestraProIcon} alt="FenestraPro"/>
                                            <br/>
                                            FenestraPro Website
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })()}
                </div>
                <ToastContainer position="bottom-right" autoClose="1500" />
            </div>
        )
    }
}

Home.contextType = AppContext;
export default Home;