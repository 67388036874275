import { Component } from 'react';
import AppContext from '../AppContext';
import API from '../Api';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import { EMAIL_REGEX } from "../Regex";

class AsmDashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            fetching: true,
            submitting: false,
            tab_selected: "organizations", //organizations || users
            modal_type: "", //add-organization || edit-organization || add-user || edit-user || closed if empty string
            organizations: [],
            organization_details: null,
            user_details: null,
            new_organization: {
                name: "",
                region:"",
                user_1_firstname : "",
                user_1_lastname: "",
                user_1_email: "",
                user_2_firstname: "",
                user_2_lastname: "",
                user_2_email: "",
                custom_message: ""
            },
            new_user:{
                organization_id: "",
                organization_name: "",
                email: "",
                firstname: "",
                lastname: ""
            }
        }
    }

    componentWillMount(){
        this.updateData();
    }

    updateData(){
        API.getOrganizationsByVendorManager()
        .then( organizations => {
            this.setState({
                organizations: organizations,
                fetching: false
            }, () => {
                console.log(this.state.organizations);
            });
        })
        .catch(error => {
            this.setState({
                fetching: false
            });
        });
    }

    render(){

        if(this.state.fetching === true){
            return <div className="loading">Loading&#8230;</div>
        }
        else{
            return ( 
                <div id='vendor-management-container'>
                    <h1 className="page-title">
                        License Management 
                        <button style={{fontSize: "24px", padding: "16px"}} className="theme-btn no-margin pull-right"
                            onClick={e => this.setState({
                                modal_type: "add-organization",
                                new_organization: {
                                    name: "",
                                    region: "",
                                    user_1_firstname : "",
                                    user_1_lastname: "",
                                    user_1_email: "",
                                    user_2_firstname: "",
                                    user_2_lastname: "",
                                    user_2_email: "",
                                    custom_message: ""
                                }
                            })} 
                        >Create New Organization</button>
                    </h1>
                    <div id='vendor-management-tabs'>
                        <div className='tab-list'>
                            <div onClick={()=>this.setState({ tab_selected: "organizations"})} className={this.state.tab_selected==="organizations"? "active" : ""}>ORGANIZATIONS</div>
                            <div onClick={()=>this.setState({ tab_selected: "users"})} className={this.state.tab_selected==="users"? "active" : ""}>USERS</div>
                        </div>
                        {(() => {
                            if(this.state.tab_selected === "organizations"){
                                return (
                                    <div className="table-wrapper">
                                        <h1>Organizations ( {this.state.organizations.length} )</h1>
                                        <DataTable
                                            highlightOnHover={true}
                                            pointerOnHover={true}
                                            dense={true}
                                            keyFied="_id"
                                            onRowClicked={ row => this.setState({
                                                organization_details: row,
                                                modal_type: "edit-organization"
                                            })}
                                            columns={[
                                                {
                                                    name: 'Organization ID',
                                                    selector: row => row._id,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Organization Name',
                                                    selector: row => row.name,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'License Key',
                                                    selector: row => row.license_key,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Registration Date',
                                                    selector: row => row.registration_date,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'End Date',
                                                    selector: row => row.end_date,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Region',
                                                    selector: row => row.region,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Users',
                                                    selector: row => row.users.length + " of " + row.product_details.assigned_users,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Manage-Users',
                                                    selector: row => (`Click to Manage`),
                                                    sortable: false,
                                                },
                                            ]}
                                            data={this.state.organizations}
                                        />
                                    </div>
                                );
                            }
                            else if(this.state.tab_selected === "users"){
                                return (
                                    <div className="table-wrapper">
                                        <h1>Users ( {[].concat.apply([], this.state.organizations.map(org => org.users)).length} )</h1>
                                        <DataTable
                                            highlightOnHover={true}
                                            pointerOnHover={true}
                                            dense={true}
                                            keyFied="_id"
                                            onRowClicked={ row => this.setState({
                                                user_details: row,
                                                modal_type: "edit-user"
                                            })}
                                            columns={[
                                                {
                                                    name: 'User ID',
                                                    selector: row => row._id,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Organization Name',
                                                    selector: row => row.organization_name,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Full Name',
                                                    selector: row => row.firstname + " " + row.lastname,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Email',
                                                    selector: row => row.email,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Verified',
                                                    selector: row => row.verified ? "Yes" : "No",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Last Access',
                                                    selector: row => row.last_login,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Revit Version',
                                                    selector: row => row.revit_version,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Msi Version',
                                                    selector: row => row.revit_msi_version,
                                                    sortable: true,
                                                },
                                            ]}
                                            data={[].concat.apply([], this.state.organizations.map(org => org.users.map(u => Object.assign({}, u, {organization_name: org.name}))))}
                                        />
                                    </div>
                                );
                            }
                            else{
                                return (
                                    <></>
                                ); 
                            }
                        })()}
                    </div>
                    {(() => {
                        if (this.state.modal_type !== "") {
                            return (
                                <Modal ariaHideApp={false} isOpen={true} onRequestClose={() => this.setState({user_details: null, organization_details: null, modal_type: ""})} contentLabel="Example Modal"
                                    style={{
                                        overlay: {
                                            position: 'fixed',
                                            background: 'rgba(0,0,0,.5)'
                                        },
                                        content: {
                                            width: "600px",
                                            maxHeight: "80%",
                                            position: "relative",
                                            top: "50%",
                                            padding: "16px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            transform: "translateY(-50%)",
                                            transition: "opacity 2s"
                                        }
                                    }}
                                >
                                    {this.showModalContent()}
                                </Modal>
                            )
                        }
                    })()}
                     <ToastContainer position="bottom-right" autoClose="1500" />
                </div>
            );
        }
    }

    showModalContent(){
        
        if(this.state.modal_type === "add-organization"){
            return (
                <div>
                    <h2>New License</h2>
                    <i>If you need to add a user to an existing license you can click an organization and then click the button "Add User".</i>
                    <table className="modal-table">
                        <tbody>
                            <tr>
                                <td style={{fontSize: "16px", fontWeight: "bold"}}>
                                    Organization Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.name} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { name: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{fontSize: "16px", fontWeight: "bold"}}>
                                    Region
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.region} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { region: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{paddingTop: "12px"}}>
                                <b>USER 1</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Email
                                </td>
                                <td>
                                    <input type="email" className="theme-input" value={this.state.new_organization.user_1_email} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_1_email: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                First Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.user_1_firstname} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_1_firstname: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Last Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.user_1_lastname} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_1_lastname: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{paddingTop: "12px"}}>
                                <b>USER 2</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Email
                                </td>
                                <td>
                                    <input type="email" className="theme-input" value={this.state.new_organization.user_2_email} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_2_email: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                First Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.user_2_firstname} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_2_firstname: e.target.value })})}/>
                                </td>
                            </tr>
                            
                            <tr>
                                <td>
                                Last Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_organization.user_2_lastname} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { user_2_lastname: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{paddingTop: "24px"}}>
                                    <textarea className="theme-input" value={this.state.new_organization.custom_message} onChange={e => this.setState({new_organization: Object.assign({}, this.state.new_organization, { custom_message: e.target.value })})} placeholder="Optional custom message that will be added to the invitation email"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center" style={{paddingTop: "12px"}}>
                        <button onClick={e => this.createLicense()} disabled={this.state.submitting} className="theme-btn full-width">Create License</button>
                    </div>
                </div>
            );
        }
        else if(this.state.modal_type === "edit-organization"){
            return (
                <div>
                    <h2>{this.state.organization_details.name}</h2>
                    <table className="modal-table">
                        <tbody>
                            <tr>
                                <td>
                                Organization Name
                                </td>
                                <td>
                                    {this.state.organization_details.name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Organization ID
                                </td>
                                <td>
                                    {this.state.organization_details._id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                License Key
                                </td>
                                <td>
                                    {this.state.organization_details.license_key}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Registration Date
                                </td>
                                <td>
                                    {this.state.organization_details.registration_date}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                End Date
                                </td>
                                <td>
                                    {this.state.organization_details.end_date}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Region
                                </td>
                                <td>
                                    {this.state.organization_details.region}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Users
                                </td>
                                <td>
                                    {this.state.organization_details.users.length} of {this.state.organization_details.product_details.assigned_users}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={e => this.setState({new_user: { organization_id: this.state.organization_details._id, organization_name: this.state.organization_details.name, email: "", firstname: "", lastname: "" }, modal_type: "add-user"})} disabled={this.state.submitting ||  this.state.organization_details.users.length >= this.state.organization_details.product_details.assigned_users } className="theme-btn pull-right" style={{margin: "8px 0px"}}>Add User</button>
                    {  this.state.organization_details.users.length >= this.state.organization_details.product_details.assigned_users ?  
                        <div className="align-center">
                            If you need to increase the number of users for this license, please send a request to <a href={"mailto:gbenson@fenestrapro.com?subject=Guardian users request - " + this.state.organization_details.name}>gbenson@fenestrapro.com</a>
                        </div> 
                        : 
                        ""
                    }
                    <DataTable
                        highlightOnHover={true}
                        pointerOnHover={true}
                        dense={true}
                        keyFied="_id"
                        onRowClicked={ row => this.setState({
                            user_details: row,
                            modal_type: "edit-user"
                        })}
                        columns={[
                            {
                                name: 'User ID',
                                selector: row => row._id,
                                sortable: true,
                            },
                            {
                                name: 'Full Name',
                                selector: row => row.firstname + " " + row.lastname,
                                sortable: true,
                            },
                            {
                                name: 'Email',
                                selector: row => row.email,
                                sortable: true,
                            },
                            {
                                name: 'Verified',
                                selector: row => row.verified ? "Yes" : "No",
                                sortable: true,
                            }
                        ]}
                        data={this.state.organization_details.users.map(u => Object.assign({}, u, { organization_name : this.state.organization_details.name}))}
                    />
                </div>
            );
        }
        else if(this.state.modal_type === "add-user"){
            return (
                <div>
                    <h2>New User</h2>
                    <table className="modal-table">
                        <tbody>
                            <tr>
                                <td>
                                Organization ID
                                </td>
                                <td>
                                {this.state.new_user.organization_id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Organization Name
                                </td>
                                <td>
                                    {this.state.new_user.organization_name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Email
                                </td>
                                <td>
                                    <input type="email" className="theme-input" value={this.state.new_user.email} onChange={e => this.setState({new_user: Object.assign({}, this.state.new_user, { email: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                First Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_user.firstname} onChange={e => this.setState({new_user: Object.assign({}, this.state.new_user, { firstname: e.target.value })})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Last Name
                                </td>
                                <td>
                                    <input type="text" className="theme-input" value={this.state.new_user.lastname} onChange={e => this.setState({new_user: Object.assign({}, this.state.new_user, { lastname: e.target.value })})}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center" style={{paddingTop: "12px"}}>
                        <button onClick={e => this.addUser()} disabled={this.state.submitting || this.state.organization_details.product_details.assigned_users >= this.state.organization_details } className="theme-btn full-width">Add User</button>
                    </div>
                </div>
            );
        }
        else if(this.state.modal_type === "edit-user"){
            return (
                <div>
                    <h2>User</h2>
                    <table className="modal-table">
                        <tbody>
                            <tr>
                                <td>
                                User ID
                                </td>
                                <td>
                                    {this.state.user_details._id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Organization Name
                                </td>
                                <td>
                                    {this.state.user_details.organization_name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                First Name
                                </td>
                                <td>
                                    {this.state.user_details.firstname}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last Name
                                </td>
                                <td>
                                    {this.state.user_details.lastname}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Email
                                </td>
                                <td>
                                    {this.state.user_details.email}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Account Verified
                                </td>
                                <td>
                                    {this.state.user_details.verified ? "Yes" : "No" }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Account Created Date
                                </td>
                                <td>
                                    {this.state.user_details.registration_date }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center" style={{paddingTop: "12px"}}>
                        <button onClick={e => this.deleteUser()} disabled={this.state.submitting} className="delete-btn theme-btn">Delete User</button>
                        <button onClick={e => this.resendUserInvitation()} disabled={this.state.submitting} className="theme-btn">Re-send Invitation Email</button>
                    </div>
                </div>
            );
        }
    }

    createLicense(){
        let organization_name = this.state.new_organization.name;
        let organization_region = this.state.new_organization.region;

        let user_1_firstname = this.state.new_organization.user_1_firstname.trim();
        let user_1_lastname = this.state.new_organization.user_1_lastname.trim();
        let user_1_email = this.state.new_organization.user_1_email.trim();

        let user_2_firstname = this.state.new_organization.user_2_firstname.trim();
        let user_2_lastname = this.state.new_organization.user_2_lastname.trim();
        let user_2_email = this.state.new_organization.user_2_email.trim();

        let custom_message = this.state.new_organization.custom_message.trim();

        if(user_1_email.length > 0){
            if(!EMAIL_REGEX.test(user_1_email)){
                return toast.error("The email for USER 1 has an invalid format.", { autoClose: 5000 });
            }
            if(user_1_firstname.length === 0 || user_1_lastname.length === 0){
                return toast.error("First name and last name for USER 1 are required", { autoClose: 5000 });
            }
        }

        if(user_2_email.length > 0){
            if(!EMAIL_REGEX.test(user_2_email)){
                return toast.error("The email for USER 2 has an invalid format.", { autoClose: 5000 });
            }
            if(user_2_firstname.length === 0 || user_2_lastname.length === 0){
                return toast.error("First name and last name for USER 2 are required", { autoClose: 5000 });
            }
        }

        this.setState({submitting: true});
        console.log(organization_region);

        API.createVendorManagedLicense(organization_name, organization_region, user_1_firstname, user_1_lastname, user_1_email, user_2_firstname, user_2_lastname, user_2_email, custom_message)
        .then(() => {
            this.setState({
                modal_type: "",
                new_organization: {
                    name: "",
                    region:"",
                    user_1_firstname : "",
                    user_1_lastname: "",
                    user_1_email: "",
                    user_2_firstname: "",
                    user_2_lastname: "",
                    user_2_email: "",
                    custom_message: ""
                },
            });
            this.updateData();
            toast.success("License created.");  
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    addUser(){

        let organization_id = this.state.new_user.organization_id;
        let email = this.state.new_user.email.trim();
        let firstname = this.state.new_user.firstname.trim();
        let lastname = this.state.new_user.lastname.trim();
        if(!EMAIL_REGEX.test(email)){
            return toast.error("The email has an invalid format.", { autoClose: 5000 });
        }
        if(firstname.length === 0 || lastname.length === 0){
            return toast.error("First name and last name are required", { autoClose: 5000 });
        }

        this.setState({submitting: true});

        API.addUserToVendorManagedLicense({
            email: email,
            firstname: firstname,
            lastname: lastname,
            username: "",
            is_main: false,
            platform_type: this.context.platform_type,
            organization_id: organization_id
        })
        .then(() => {
            this.setState({
                modal_type: "",
                user_details: null,
                new_user:{
                    organization_id: "",
                    organization_name: "",
                    email: "",
                    firstname: "",
                    lastname: ""
                }
            });
            this.updateData();
            toast.success("User added.");  
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    deleteUser(){
        let user_id = this.state.user_details._id;
        let organization_id = this.state.user_details.organization;
      
        if(window.confirm("Please confirm that you want to delete this user")){
            this.setState({ submitting: true});
            API.deleteUserFromVendorManagedLicense(user_id, organization_id)
            .then(response => {
                this.setState({
                    modal_type: "",
                    user_details: null,
                });
                this.updateData();
                toast.success("User Deleted.");
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({ submitting: false});
            });
        }
    }

    resendUserInvitation(){
        this.setState({ submitting: true});
        let user_id = this.state.user_details._id;
        API.sendInvite(user_id)
        .then( response => {
            toast.success("Invitation email sent.");
        })
        .catch( error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({ submitting: false});
        });
    }
}

AsmDashboard.contextType = AppContext;
export default AsmDashboard;