import { Component } from 'react';
//
import AppContext from '../AppContext';
import API from '../Api';
import GearIcon from "../img/gear_icon.png";
import Modal from 'react-modal';
import ShareOptions from "./ShareOptions";
import Tile from "./Tile";
// modules
import unit_converter from '../modules/unit_converter';

class Codes extends Component {

    constructor(props) {
        super(props);
        this.codeCreate = this.codeCreate.bind(this);
        this.codeValueChange = this.codeValueChange.bind(this);
        this.codeValuesUpdate = this.codeValuesUpdate.bind(this);
        this.state = {
            user_id: null,
            numeric_codes: null,
            codes: [],
            groups: [],
            search: "",
            fetching: true,
            displayModal: false,
            modalCodeData: null,
            modalMode: 'options',
            grouped: true,
            noContentMsg: "none"
        }
    }

    componentDidMount() {
        Modal.setAppElement('body');
        //
        API.getCodes().then(data => {
            if (data.codes.length > 0) {
                this.setState({
                    user_id: data.user_id,
                    codes: data.codes,
                    fetching: false
                });
            } else {
                this.setState({ noContentMsg: "block" });
            }
        }).then(() => {
            API.getUserSettings(this.state.user_id)
                .then((userSettings) => {
                    this.setState({ numeric_codes: unit_converter.init(userSettings.numeric_system) }, () => {
                        //console.log(this.state.numeric_codes);
                    })
                }).catch(error => {
                    console.warn("Error");
                });
        }).catch(error => {
            console.warn("Error");
        });
    }

    render() {
        return (
            <AppContext.Consumer>
                {context => (
                    <div id="codes-page">
                        <h1 className="page-title">CODES</h1>
                        <div id="codes-container-tiles">
                            <div id="search-container">
                                Search:
                                <input id="search" value={this.state.search} onChange={(e) => this.searchTerm(e.target.value)} />
                                <button className='btn btn-sm btn-primary' id='create-code' onClick={(e) => this.showModal(e, {
                                    data: {
                                        name: '',
                                        values: {
                                            roof: 0,
                                            wall: 0,
                                            floor: 0,
                                            glass: 0
                                        }
                                    },
                                    status: true,
                                    mode: "create"
                                })} >Create Code
                                </button>
                            </div>
                            <div id="desc">
                                <p>
                                    The Revit Add-in may recommend suggested Energy Codes for the project (depending on the project region).<br></br>
                                    The locations currently include North America, UK, Ireland and Australia. More locations are to be added. You may choose to use these or create your own.<br></br>
                                    Some regions may not display any suggestions but there is a default placeholder Code in the Revit Add-in which may be used temporarily to continue.<br></br>
                                    You can always return later and adjust the reference values.<br></br><br></br>
                                    More information about Energy Codes is available on our Support Site <a target="_blank" href="https://support.fenestrapro.com/knowledgebase/6-1-1-1-building-energy-codes/">here</a>.<br></br><br></br>
                                    On this page, you may also create an Energy Code/Building Regulation with reference thermal values for project elements (roofs, floors, walls and glazing).<br></br>
                                    The application will then check if current element properties in the model comply or not. You may also share a Code with other FenestraPro users or they may also share Codes with you.
                                </p>
                            </div>
                            <div id="empty-message" style={{ display: this.state.noContentMsg }}>
                                <span>No codes to display.</span>
                            </div>
                            <div id="tiles">
                                {(() => {
                                    return (
                                        this.listCodes()
                                    )
                                })()
                                }
                            </div>
                        </div>

                        {
                            (() => {
                                if (this.state.displayModal === true) {
                                    return (
                                        <Modal
                                            isOpen={true}
                                            onRequestClose={(e) => this.setState({ displayModal: false })}
                                            contentLabel="Example Modal"
                                            style={{
                                                overlay: {
                                                    position: 'fixed',
                                                    background: 'rgba(0,0,0,.5)'
                                                },
                                                content: {
                                                    width: "600px",
                                                    maxHeight: "80%",
                                                    position: "relative",
                                                    top: "50%",
                                                    padding: "16px",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    transform: "translateY(-50%)",
                                                    transition: "opacity 2s"
                                                }
                                            }}>
                                            <div id='project-modal-content'>
                                                {(() => {
                                                    if (this.state.modalMode === "values") {
                                                        if (this.state.user_id !== this.state.modalCodeData.creator) {
                                                            //shared so uneditable
                                                            return (
                                                                <div className="fpe-file-name-modal">
                                                                    <p>{`Code Name: ${this.state.modalCodeData.name}`}</p>
                                                                    <p>Roof ({this.state.numeric_codes.uv_label_short}):&nbsp;<input disabled value={unit_converter.uv_val(this.state.numeric_codes.type, this.state.modalCodeData.values.roof)} className="inputNum" type="number" ></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Floor ({this.state.numeric_codes.uv_label_short}):&nbsp;<input disabled value={unit_converter.uv_val(this.state.numeric_codes.type, this.state.modalCodeData.values.floor)} className="inputNum" type="number"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Wall ({this.state.numeric_codes.uv_label_short}):&nbsp;<input disabled value={unit_converter.uv_val(this.state.numeric_codes.type, this.state.modalCodeData.values.wall)} className="inputNum" type="number"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Glass (U-VAL):&nbsp;<input disabled value={unit_converter.uv_glass_val(this.state.numeric_codes.type, this.state.modalCodeData.values.glass)} className="inputNum" type="number"></input>&nbsp;{this.state.numeric_codes.r_unit}</p>
                                                                    <p>Glazing (%):&nbsp;<input disabled value={this.state.modalCodeData.values.percent_glazing} className="inputNum" name="percent_glazing" type="number"></input></p>
                                                                </div>
                                                            )
                                                        } else {
                                                            //mine so editable
                                                            return (
                                                                <div className="fpe-file-name-modal">
                                                                    <p>Code Name:&nbsp;<input value={this.state.modalCodeData.name} onChange={this.codeValueChange} className="inputText" name="name" type="text" ></input></p>
                                                                    <p>Roof ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.roof} onChange={this.codeValueChange} className="inputNum" name="roof" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Floor ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.floor} onChange={this.codeValueChange} className="inputNum" name="floor" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Wall ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.wall} onChange={this.codeValueChange} className="inputNum" name="wall" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                    <p>Glass (U-VAL):&nbsp;<input value={this.state.modalCodeData.values.glass} onChange={this.codeValueChange} className="inputNum" name="glass" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.r_unit}</p>
                                                                    <p>Glazing (%):&nbsp;<input value={this.state.modalCodeData.values.percent_glazing} onChange={this.codeValueChange} className="inputNum" name="percent_glazing" type="number" min="1" max="100" step="1"></input></p>
                                                                </div>
                                                            )
                                                        }
                                                    } else if (this.state.modalMode === "create") {
                                                        return (
                                                            <div className="fpe-file-name-modal">
                                                                <p>Code Name:&nbsp;<input value={this.state.modalCodeData.name} onChange={this.codeValueChange} className="inputText" name="name" type="text" ></input></p>
                                                                <p>Roof ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.roof} onChange={this.codeValueChange} className="inputNum" name="roof" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                <p>Floor ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.floor} onChange={this.codeValueChange} className="inputNum" name="floor" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                <p>Wall ({this.state.numeric_codes.uv_label_short}):&nbsp;<input value={this.state.modalCodeData.values.wall} onChange={this.codeValueChange} className="inputNum" name="wall" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.uv_unit}</p>
                                                                <p>Glass (U-VAL):&nbsp;<input value={this.state.modalCodeData.values.glass} onChange={this.codeValueChange} className="inputNum" name="glass" type="number" min="0.001" step="0.001"></input>&nbsp;{this.state.numeric_codes.r_unit}</p>
                                                                <p>Glazing (%):&nbsp;<input value={this.state.modalCodeData.values.percent_glazing} onChange={this.codeValueChange} className="inputNum" name="percent_glazing" type="number" min="0" max="100" step="1"></input></p>
                                                                <button className="theme-btn" onClick={this.codeCreate} disabled={this.state.submitting}>Create</button>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="fpe-file-name-modal">
                                                                <p>Code Name: {this.state.modalCodeData.name}</p>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div id='project-modal-mode-container'>
                                                {(() => {
                                                    if (this.state.modalMode === "options") {
                                                        if (this.state.user_id !== this.state.modalCodeData.creator) {
                                                            return (
                                                                <div>
                                                                    <button className="theme-btn" onClick={e => this.setState({ modalMode: "share" })} disabled={this.state.submitting}>Share Code</button>
                                                                    <button className="theme-btn delete-btn" onClick={e => this.hideCode()} disabled={this.state.submitting}>Remove Code</button>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div>
                                                                    <button className="theme-btn" onClick={e => this.setState({ modalMode: "share" })} disabled={this.state.submitting}>Share Code</button>
                                                                    <button className="theme-btn delete-btn" onClick={e => this.deleteCode()} disabled={this.state.submitting}>Delete Code</button>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                    else if (this.state.modalMode === "share") {
                                                        return (
                                                            <div>
                                                                <ShareOptions share_item="codes" item_id={this.state.modalCodeData._id} />
                                                                <button className="go-back" onClick={() => this.setState({ modalMode: "options" })}>« Go Back</button>
                                                            </div>
                                                        )
                                                    } else if (this.state.modalMode === "values" && this.state.user_id === this.state.modalCodeData.creator) {
                                                        return (
                                                            <div>
                                                                <button className="theme-btn" onClick={this.codeValuesUpdate} disabled={this.state.submitting}>Update</button>
                                                            </div>
                                                        )
                                                    }

                                                })()}
                                            </div>
                                        </Modal>
                                    )
                                }
                            })()
                        }
                    </div>
                )}
            </AppContext.Consumer>

        )
    }

    showModal = (e, data) => {
        e.stopPropagation();
        // unit-conversion
        console.log(data.data.values)
        if ((!data.data.values.percent_glazing) ? data.data.values.percent_glazing = 0 : data.data.values.percent_glazing)
            if (this.state.numeric_codes.type === 'imperial') {
                data.data.values.roof = unit_converter.uv_val(this.state.numeric_codes.type, data.data.values.roof);
                data.data.values.wall = unit_converter.uv_val(this.state.numeric_codes.type, data.data.values.wall);
                data.data.values.floor = unit_converter.uv_val(this.state.numeric_codes.type, data.data.values.floor);
                data.data.values.glass = unit_converter.uv_glass_val(this.state.numeric_codes.type, data.data.values.glass);
            }
        console.log(data.data.values)
        // save to state
        this.setState({
            modalCodeData: data.data,
            displayModal: data.status,
            modalMode: data.mode
        }, () => {
            console.log(this.state.modalCodeData);
        });
    }

    codeValueChange(event) {
        let modalCodeData = { ...this.state.modalCodeData }
        if (event.target.name === "name") {
            modalCodeData.name = event.target.value;
        } else {
            modalCodeData.values[event.target.name] = parseFloat(Number(event.target.value).toFixed(3));
        }
        this.setState({ modalCodeData }, () => {
            console.log(this.state.modalCodeData)
        });
    }

    codeValuesUpdate() {
        if (window.confirm("Confirm that you want to update this code for you and anyone it was shared with?")) {
            let modalCodeData = { ...this.state.modalCodeData }
            if (modalCodeData.name.length < 4) {
                //window.alert('Code needs a name at least 5-characters long');
                console.log('Code needs a name at least 5-characters long');
            } else {
                if (this.state.numeric_codes.type === 'imperial') {
                    modalCodeData.values.roof = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.roof);
                    modalCodeData.values.wall = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.wall);
                    modalCodeData.values.floor = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.floor);
                    modalCodeData.values.glass = unit_converter.uv_glass_val_invert(this.state.numeric_codes.type, modalCodeData.values.glass);
                }
                this.setState({
                    displayModal: false,
                    submitting: true
                });
                API.updateCodeById(modalCodeData)
                    .then(() => {
                        console.log(`Update successful: this page will now auto-refresh...`);
                        setTimeout(() => {
                            //window.location = "/codes";
                            window.location = window.location.href;
                        }, 500);
                    })
                    .catch(error => {
                        //window.alert(error.responseText);
                        console.log(error.responseText);
                    })
                    .always(() => {
                        this.setState({ submitting: false });
                    });
            }
        }
    }

    codeCreate() {
        if (window.confirm("Confirm that you want to create this code?")) {
            let modalCodeData = { ...this.state.modalCodeData };
            console.log(modalCodeData);
            //validate
            if (modalCodeData.name.length < 4) {
                //window.alert('Code needs a name at least 5-characters long');
                console.log('Code needs a name at least 5-characters long');
            } else {
                if (this.state.numeric_codes.type === 'imperial') {
                    modalCodeData.values.roof = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.roof);
                    modalCodeData.values.wall = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.wall);
                    modalCodeData.values.floor = unit_converter.uv_val(this.state.numeric_codes.type, modalCodeData.values.floor);
                    modalCodeData.values.glass = unit_converter.uv_glass_val_invert(this.state.numeric_codes.type, modalCodeData.values.glass);
                }
                this.setState({
                    displayModal: false,
                    submitting: true
                });
                API.createCode(modalCodeData)
                    .then(() => {
                        console.log(`Code created: this page will now auto-refresh...`);
                        setTimeout(() => {
                            //window.location = "/codes";
                            window.location = window.location.href;
                        }, 500);
                    })
                    .catch(error => {
                        //window.alert(error.responseText);
                        console.log(error.responseText);
                    })
                    .always(() => {
                        this.setState({ submitting: false });
                    });
            }
        }
    }

    listCodes() {
        return this.state.codes
            .filter(code => {
                if (this.state.search === "") {
                    return true;
                }
                else {
                    return code.name.toLowerCase().includes(this.state.search);
                }
            })
            .map(code => {
                return (
                    <Tile
                        key={code._id}
                        tileClick={this.showModal}
                        tileClickData={{
                            data: code,
                            status: true,
                            mode: "values"
                        }}
                        title={`Name: ${code.name}`}
                        _subtitle={`U-Values: ${code.values.roof}(roof) - ${code.values.floor}(floor) - ${code.values.wall}(wall) - ${code.values.glass}(glass)`}
                        created={code.created}
                        creator={code.creator === this.state.user_id ? `You've shared this with ${code.shared_count} users` : `Shared by: ${code.creator_email}`}
                        settingsIcon={GearIcon}
                        settingsClick={this.showModal}
                        settingsData={{
                            data: code,
                            status: true,
                            mode: "options"
                        }}
                    />
                );
            });
    }

    deleteCode() {
        let id = this.state.modalCodeData.id;
        if (window.confirm("Confirm that you want to delete this code for you and anyone it was shared with?")) {
            this.setState({
                displayModal: false,
                submitting: true
            });
            API.deleteCodeById(id)
                .then(() => {
                    console.log(`Deleted code: this page will now auto-refresh`);
                    setTimeout(() => {
                        //window.location = "/codes";
                        window.location = window.location.href;
                    }, 500);
                })
                .catch(error => {
                    console.log(error.responseText);
                    //window.alert(error.responseText);
                })
                .always(() => {
                    this.setState({ submitting: false });
                });
        }
    }

    hideCode() {
        let id = this.state.modalCodeData._id;
        if (window.confirm("Confirm that you want dont want to see this code anymore?")) {
            this.setState({
                displayModal: false,
                submitting: true
            }); API.hideCodeById(id)
                .then(() => {
                    console.log(`Hide code: this page will now auto-refresh`);
                    setTimeout(() => {
                        window.location = "/codes";
                    }, 500);
                })
                .catch(error => {
                    console.log(error);
                })
                .always(() => {
                    this.setState({ submitting: false });
                });
        }
    }

    searchTerm(term) {
        this.setState({ search: term.toLowerCase().trim() });
    }
}

Codes.contextType = AppContext;
export default Codes;