import { Component } from 'react';
import AppContext from '../AppContext';
import API from '../Api';
import { EMAIL_REGEX } from "../Regex";

class ShareOptions extends Component {
    
    constructor(props){
        super(props);
        //this.props.share_item = projects || fpe_projects || reports || custom_glazings
        this.state = {
            share_type: "", //email || team, || user
            target_user_id: "",
            target_team_id: "",
            target_email: "",
            submitting: false,
            sharing_result: "",
            sharing_error_message: ""
        }
    }   

    render(){
        return (
            <div className="sharing-options">
                {(() => {
                    if(this.state.sharing_result === "success"){
                        return (
                            <>
                                <div className="sharing-result-success">✔</div>
                                <h2>Shared</h2>
                            </> 
                        )
                    }
                    else if(this.state.sharing_result === "error"){
                        return (
                            <>
                                <div className="sharing-result-fail">✘</div>
                                <div className="sharing-error-text">{this.state.sharing_error_message}</div>
                            </>
                        )
                    }
                    if(this.state.share_type === "" && this.state.sharing_result === ""){
                        return (
                            <>
                                <h1>Choose a sharing option</h1>
                                <button className="sharing-option-btn" onClick={() => this.setState({share_type: "user"})}>Share with Users</button>
                                <button className="sharing-option-btn" onClick={() => this.setState({share_type: "team"})}>Share with Teams</button>
                                <button className="sharing-option-btn" onClick={() => this.setState({share_type: "email"})}>Share with Email Address</button>
                            </>
                        )
                    }
                    else if(this.state.share_type === "team" && this.state.sharing_result === ""){
                        return (
                            <>
                                <h1>Share with a Team</h1>
                                <select onChange={e => this.setState({target_team_id: e.target.value})}>
                                    <option value="">Select a Team</option>
                                    {this.context.user.teams.map(team => <option key={team._id} value={team._id}>{team.name}</option>)}
                                </select>
                                <button 
                                    className='share-submit-btn' 
                                    disabled={this.state.submitting || this.state.target_team_id === ""} 
                                    onClick={e => this.share()}
                                >
                                    {this.state.submitting ? "Sharing ..." : "Share"}
                                </button>
                                <button className='go-back' onClick={() => this.setState({share_type: ""})}>« Go Back to Share Options</button>
                            </>
                        )
                    }
                    else if(this.state.share_type === "user" && this.state.sharing_result === ""){
                       
                        return (
                            <>
                                <h1>Share with a User</h1>
                                <select onChange={e => this.setState({target_user_id: e.target.value})}>
                                    <option value="">Select a User</option>
                                    {this.context.colleagues.map(user => <option key={user._id} value={user._id}>{user.email} {user.firstname} {user.lastname}</option>)}
                                </select>
                                <button 
                                    className='share-submit-btn' 
                                    disabled={this.state.submitting || this.state.target_user_id === ""} 
                                    onClick={e => this.share()}
                                    >
                                        {this.state.submitting ? "Sharing ..." : "Share"}
                                    </button>
                                <button className='go-back' onClick={() => this.setState({share_type: ""})}>« Go Back to Share options</button>
                            </>
                        )
                    }
                    else if(this.state.share_type === "email" && this.state.sharing_result === ""){
                        return (
                            <>
                                <h1>Share using an Email</h1>
                                <input type="email" value={this.state.target_email} onChange={e => this.setState({target_email: e.target.value})} placeholder="Enter the email address here"/>
                                <button 
                                    className='share-submit-btn' 
                                    disabled={
                                        this.state.submitting || 
                                        !EMAIL_REGEX.test(this.state.target_email)
                                    } 
                                    onClick={e => this.share()}
                                    >
                                        {this.state.submitting ? "Sharing ..." : "Share"}
                                    </button>
                                <button className='go-back' onClick={() => this.setState({share_type: ""})}>« Go Back to Share Options</button>
                            </>
                        )
                    }
                })()}
            </div>
        )
    }

    share(){

        this.setState({submitting: true});
        let item_id = this.props.item_id;
        let share_type = this.state.share_type;
        let target;
        switch(this.state.share_type){
            case "user": target = this.state.target_user_id; break;
            case "team": target = this.state.target_team_id; break;
            case "email": target = this.state.target_email; break;
            default: return;
        }

        if(this.props.share_item === "custom_glazings"){
            API.shareCustomMakeup(item_id, target, share_type, true)
            .then(response => {
                console.log(response);
                this.setState({sharing_result: "success"});
            })
            .catch(error => {
                this.setState({
                    sharing_result: "error",
                    sharing_error_message: error.responseText,
                });
            })
            .always(() => {
                this.setState({
                    share_type: "",
                    target_user_id: "",
                    target_team_id: "",
                    target_email: "",
                    submitting: false
                })
            })
        }
        else if(this.props.share_item === "reports"){
            API.shareReport(item_id, target, share_type, true)
            .then(response => {
                this.setState({sharing_result: "success"});
            })
            .catch(error => {
                this.setState({
                    sharing_result: "error",
                    sharing_error_message: error.responseText,
                });
            })
            .always(() => {
                this.setState({
                    share_type: "",
                    target_user_id: "",
                    target_team_id: "",
                    target_email: "",
                    submitting: false
                })
            })
        }
        else if(this.props.share_item === "codes"){
            console.log(item_id, target, share_type);
            API.shareCode(item_id, target, share_type, true)
            .then(() => {
                this.setState({sharing_result: "success"});
            })
            .catch(error => {
                this.setState({
                    sharing_result: "error",
                    sharing_error_message: error.responseText,
                });
            })
            .always(() => {
                this.setState({
                    share_type: "",
                    target_user_id: "",
                    target_team_id: "",
                    target_email: "",
                    submitting: false
                })
            })
        }
        else if(this.props.share_item === "fpe_projects"){
            API.shareFpeProject(item_id, target, share_type, true)
            .then(response => {
                this.setState({sharing_result: "success"});
            })
            .catch(error => {
                this.setState({
                    sharing_result: "error",
                    sharing_error_message: error.responseText,
                });
            })
            .always(() => {
                this.setState({
                    share_type: "",
                    target_user_id: "",
                    target_team_id: "",
                    target_email: "",
                    submitting: false
                })
            })
        }
        else if(this.props.share_item === "projects"){
            API.shareProject(item_id, target, share_type, true)
            .then(response => {
                this.setState({sharing_result: "success"});
            })
            .catch(error => {
                this.setState({
                    sharing_result: "error",
                    sharing_error_message: error.responseText,
                });
            })
            .always(() => {
                this.setState({
                    share_type: "",
                    target_user_id: "",
                    target_team_id: "",
                    target_email: "",
                    submitting: false
                })
            })
        }
        else{
            this.setState({
                sharing_result: "error",
                share_type: "",
                target_user_id: "",
                target_team_id: "",
                target_email: "",
                submitting: false
            });
        }
    }
}

ShareOptions.contextType = AppContext;
export default ShareOptions;