import { Component } from 'react';
import Modal from 'react-modal';
import AppContext from '../AppContext';
import API from '../Api';
import GlassEnquiry from './GlassEnquiry';
import ShareOptions from './ShareOptions';
import VitroLincIcon from '../img/vendors/vitro_linc.svg';
import GuardianLincIcon from '../img/vendors/guardian_linc.svg';
import FenestraProLincIcon from '../img/vendors/fenestrapro_linc.svg';
import ViraconLincIcon from '../img/vendors/viracon_linc.svg';
import Highcharts from 'highcharts/highstock';
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsReact from 'highcharts-react-official';
highcharts3d(Highcharts);

class GlassDatabase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display_type: "table",
            dataset_type: "public",
            public_dataset: [],
            custom_dataset: [],
            page: 1,
            page_count: 0,
            dataset_length: 0,
            submitting: true,
            options_regions: [],
            options_vendors: [],
            options_sort_methods: [],
            filter_min_uv: 0,
            filter_max_uv: 7,
            filter_min_vlt: 0,
            filter_max_vlt: 1,
            filter_min_shgc: 0,
            filter_max_shgc: 1,
            filter_epd: "all",
            filter_region: "all",
            filter_vendor: "all",
            filter_sort_method: "rand",
            filter_name: "",
            glass_detail: null,
            glass_modal_mode: "specs", //specs || share || enquiry
            uv_max: 7,
            uv_unit: "W/(m²K)",
            uv_key: "uv",
            chart_x: "U-Value",
            chart_y: "Visual Light Transmittance"
        }
    }

    componentDidMount() {
        Modal.setAppElement('body');

        let uv_max, uv_unit, uv_key;

        if (this.context.settings.numeric_system === "imperial") {
            uv_max = 1.25;
            uv_unit = "BTU/(ft² hr F)";
            uv_key = "uv_imperial";
        }
        else {
            uv_max = 7;
            uv_unit = "W/(m²K)";
            uv_key = "uv";
        }

        this.setState({
            filter_max_uv: uv_max,
            uv_max: uv_max,
            uv_unit: uv_unit,
            uv_key: uv_key
        }, () => {
            Promise.all([
                API.getLincGlasses("?custom=yes&items=100&page=1"),
                API.getLincDefaultOptions()
            ])
                .then(results => {

                    this.setState({
                        public_dataset: results[0].result.data,
                        custom_dataset: results[0].custom,
                        dataset_length: results[0].result.totalCount,
                        page_count: results[0].result.pageCount,
                        options_regions: results[1].regions,
                        options_vendors: results[1].vendors,
                        options_sort_methods: results[1].sort_methods,
                        submitting: false,
                    }, () => {
                        //console.log(this.state.custom_dataset);
                    });
                });
        });
    }

    render() {
        const comp = this;
        return (
            <div id='glassdatabase'>
                <h1 className="page-title">GLASS DATABASE
                    <button className='pull-right theme-btn' onClick={() => this.toggleDatasetType()}>Switch to {this.state.dataset_type === "custom" ? "Default List" : "Custom List"}</button>
                    <button className='pull-right theme-btn' onClick={() => this.toggleDisplayType()}>Display as {this.state.display_type === "chart" ? "Table List" : "Chart"}</button>
                </h1>
                <div id="gd-container">
                    <div id='gd-left'>
                        <table id="tab-filter" className="input-container">
                            <tbody>
                                <tr>
                                    <th colSpan="2" id='search-container'>
                                        <input id="search" placeholder='Filter by description' onChange={e => this.setState({ filter_name: e.target.value.trim() })} />
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">U-VALUE [{this.state.uv_unit}]</th>
                                </tr>

                                <tr>
                                    <td>MIN</td>
                                    <td>MAX</td>
                                </tr>
                                <tr data-thermal="uv">
                                    <td><input id="uv-min" className="theme-input" type="number" min="0" max={this.context.settings.numeric_system === "imperial" ? "1.25" : "7"} step={this.context.settings.numeric_system === "imperial" ? "0.05" : "0.1"} value={this.state.filter_min_uv} onChange={e => this.setState({ filter_min_uv: parseFloat(e.target.value) })} /></td>
                                    <td><input id="uv-max" className="theme-input" type="number" min="0" max={this.context.settings.numeric_system === "imperial" ? "1.25" : "7"} step={this.context.settings.numeric_system === "imperial" ? "0.05" : "0.1"} value={this.state.filter_max_uv} onChange={e => this.setState({ filter_max_uv: parseFloat(e.target.value) })} /></td>
                                </tr>
                                <tr data-thermal="uv">
                                    <td colSpan="2" className="slider"><div id="uv-slider"></div></td>
                                </tr>
                                <tr>
                                    <th colSpan="2">VLT</th>
                                </tr>
                                <tr>
                                    <td>MIN</td>
                                    <td>MAX</td>
                                </tr>
                                <tr>
                                    <td><input id="vlt-min" className="theme-input" type="number" min="0" max="1" step="0.1" value={this.state.filter_min_vlt} onChange={e => this.setState({ filter_min_vlt: parseFloat(e.target.value) })} /></td>
                                    <td><input id="vlt-max" className="theme-input" type="number" min="0" max="1" step="0.1" value={this.state.filter_max_vlt} onChange={e => this.setState({ filter_max_vlt: parseFloat(e.target.value) })} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="slider"><div id="vlt-slider"></div></td>
                                </tr>
                                <tr>
                                    <th colSpan="2">SHGC / SF</th>
                                </tr>
                                <tr>
                                    <td>MIN</td>
                                    <td>MAX</td>
                                </tr>
                                <tr>
                                    <td><input id="shgc-min" className="theme-input" type="number" step="0.1" value={this.state.filter_min_shgc} onChange={e => this.setState({ filter_min_shgc: parseFloat(e.target.value) })} /></td>
                                    <td><input id="shgc-max" className="theme-input" type="number" step="0.1" value={this.state.filter_max_shgc} onChange={e => this.setState({ filter_max_shgc: parseFloat(e.target.value) })} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="slider"><div id="shgc-slider"></div></td>
                                </tr>
                                <tr>
                                    <th>EPD</th>
                                    <td>
                                        <select className="theme-input" onChange={e => this.setState({ filter_epd: e.target.value })} value={this.state.filter_epd}>
                                            <option value='all'>All</option>
                                            <option value='true'>Yes</option>
                                            <option value='false'>No</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>REGION</th>
                                    <td>
                                        <select className="theme-input" onChange={e => {
                                            this.setState({ filter_region: e.target.value })
                                        }
                                        } value={this.state.filter_region}>
                                            <option value="all">All Regions</option>
                                            {this.state.options_regions.map(region_name => <option key={region_name} value={region_name}>{region_name}</option>)}
                                        </select>
                                    </td>
                                </tr>
                                {
                                    this.context.user.platform_type === "fenestrapro" ?
                                        <tr>
                                            <th>VENDORS</th>
                                            <td>
                                                <select className="theme-input" onChange={e => this.setState({ filter_vendor: e.target.value })} value={this.state.filter_vendor}>
                                                    <option value="all">All Vendors</option>
                                                    {this.state.options_vendors.map(vendor_name => <option key={vendor_name} value={vendor_name}>{vendor_name}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        :
                                        ""
                                }


                                <tr>
                                    <th colSpan='2'>SORT RESULTS</th>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        <select className="theme-input" onChange={e => this.setState({ filter_sort_method: e.target.value })} value={this.state.filter_sort_method}>
                                            {this.state.options_sort_methods.map(method => <option key={method.value} value={method.value}>{method.text}</option>)}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <button id='filter' disabled={this.state.submitting} onClick={() => this.setState({ page: 1 }, () => this.updateDataset())} className="filter-btns">Apply this Filter</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id='gd-right'>
                        {(() => {
                            if (this.state.display_type === "table") {

                                return (
                                    <div id="table">
                                        <table id="glass-list">
                                            <thead>
                                                <tr className='fpro-div-color'>
                                                    {this.state.dataset_type === "custom" ? <th data-sort="origin">ORIGIN</th> : null}
                                                    <th data-sort="created_at" className='date_created'>DATE</th>
                                                    <th data-sort="vendor" title='Glass fabricator'>VENDOR</th>
                                                    <th data-sort="description" title="Description">GLASS DESCRIPTION</th>
                                                    <th data-sort="uv" title='U-value'>U-VAL <br /> [{this.state.uv_unit}]<span className='uval-unit-label'></span></th>
                                                    <th data-sort="shgc" title='Solar Heat Gain Coefficient / Solar Factor'>SHGC / SF</th>
                                                    <th data-sort="vlt" title='Visual Light Transmittance'>VLT</th>
                                                    <th data-sort="calculation_standard" title='Calculation Standard'>CALC STD</th>
                                                    <th data-sort="epd" title='Environment Product Declaration'>EPD</th>
                                                    <th data-sort="regions" title='Region'>REGION</th>
                                                </tr>
                                            </thead>
                                            <tbody id="table-body" display="none">
                                                {(() => {
                                                    if (this.state.submitting === true) {
                                                        return (
                                                            <tr>
                                                                <td colSpan="9">
                                                                    Loading Glasses
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    else {
                                                        return this.showDataset();
                                                    }
                                                })()}

                                            </tbody>
                                        </table>
                                    </div>
                                );
                            }
                            else if (this.state.display_type === "chart") {
                                return (
                                    <div id="glass-database-chart" >
                                        <div id='x-axis-selector'>
                                            X Axis:
                                            <select value={this.state.chart_x} onChange={e => this.setState({ chart_x: e.target.value })}>
                                                <option value="U-Value">U-Value</option>
                                                <option value="Solar Heatgain Coefficient">Solar Heatgain Coefficient</option>
                                                <option value="Visual Light Transmittance">Visual Light Transmittance</option>
                                            </select>
                                        </div>
                                        <div id='y-axis-selector'>
                                            Y Axis:
                                            <select value={this.state.chart_y} onChange={e => this.setState({ chart_y: e.target.value })}>
                                                <option value="U-Value">U-Value</option>
                                                <option value="Solar Heatgain Coefficient">Solar Heatgain Coefficient</option>
                                                <option value="Visual Light Transmittance">Visual Light Transmittance</option>
                                            </select>
                                        </div>
                                        <div id='glass-chart-wrapper'>
                                            <HighchartsReact

                                                highcharts={Highcharts}
                                                options={{
                                                    chart: {
                                                        zoomType: 'xy',
                                                        height: '500px',
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    boost: {
                                                        useGPUTranslations: true,
                                                        usePreAllocated: true
                                                    },
                                                    xAxis: {
                                                        min: 0,
                                                        max: this.state.chart_x === "U-Value" ? this.state.uv_max : 1,
                                                        gridLineWidth: 1,
                                                        title: {
                                                            text: this.state.chart_x === "U-Value" ? this.state.chart_x + " [" + this.state.uv_unit + "]" : this.state.chart_x
                                                        }
                                                    },
                                                    yAxis: {
                                                        min: 0,
                                                        max: this.state.chart_y === "U-Value" ? this.state.uv_max : 1,
                                                        minPadding: 0,
                                                        maxPadding: 0,
                                                        title: {
                                                            text: this.state.chart_y === "U-Value" ? this.state.chart_y + " [" + this.state.uv_unit + "]" : this.state.chart_y
                                                        }
                                                    },

                                                    title: {
                                                        text: ''
                                                    },
                                                    legend: {
                                                        enabled: false
                                                    },
                                                    tooltip: {
                                                        formatter: function () {
                                                            return `<div style="width: 150px; font-weight: bold">
                                                                ${this.point.glass.source.toUpperCase()}
                                                                <br/>
                                                                ${this.point.glass.description}
                                                                <br/>
                                                                UV [${this.point.uv_unit}] = ${this.point.glass[this.point.uv_key]} 
                                                                <br/>
                                                                VLT = ${this.point.glass.vlt}
                                                                <br/> 
                                                                SHGC= ${this.point.glass.shgc[0].value} 
                                                            </div>`;
                                                        },
                                                    },
                                                    series: [{
                                                        type: 'scatter',
                                                        color: '#666',
                                                        data: this.getChartSeries(),
                                                        marker: {
                                                            radius: 4
                                                        },
                                                    }],
                                                    plotOptions: {
                                                        series: {
                                                            point: {
                                                                events: {
                                                                    click() {
                                                                        let point = this;
                                                                        let glass_detail = point.glass;
                                                                        comp.setState({ glass_detail: glass_detail });
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if (this.state.dataset_type === "public") {
                                return (
                                    <div id="footer-table">
                                        <div id="items">Showing result: {(this.state.page - 1) * 100} to {this.state.submitting === true ? "..." : (this.state.page - 1) * 100 + this.state.public_dataset.length}</div>
                                        <div id="pagination" className='pull-right'>
                                            <button className="glyphicon glyphicon-backward page-scroll-btn" title="Previous Page" disabled={this.state.page <= 1 || this.state.submitting === true} onClick={() => this.setState({ page: parseInt(this.state.page) - 1 }, () => this.updateDataset())}></button>
                                            <select className="footer-btn" name="page" id="page-select" value={this.state.page} onChange={e => this.setState({ page: parseInt(e.target.value) }, () => this.updateDataset())}>
                                                {[...Array(this.state.page_count).keys()].map(page_number => <option key={page_number} value={page_number + 1}>Page {page_number + 1}</option>)}
                                            </select>
                                            <button className="glyphicon glyphicon-forward page-scroll-btn" title="Next Page" disabled={this.state.page >= this.state.page_count || this.state.submitting === true} onClick={() => this.setState({ page: parseInt(this.state.page) + 1 }, () => this.updateDataset())}></button>
                                        </div>
                                    </div>
                                )
                            }
                            else if (this.state.dataset_type === "custom") {
                                return (
                                    <div id="footer-table">
                                        <div id="items">Showing {this.state.custom_dataset.length} custom make-ups</div>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
                {(() => {
                    if (this.state.glass_detail != null) {
                        let vendor_logo;
                        switch (this.state.glass_detail.source) {
                            case "guardian": vendor_logo = GuardianLincIcon; break;
                            case "vitro": vendor_logo = VitroLincIcon; break;
                            case "viracon": vendor_logo = ViraconLincIcon; break;
                            default: vendor_logo = FenestraProLincIcon; break;
                        }
                        return (
                            <Modal ariaHideApp={false} isOpen={true} onRequestClose={() => this.setState({ glass_detail: null, glass_modal_mode: "specs" })} contentLabel="Example Modal"
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        background: 'rgba(0,0,0,.5)'
                                    },
                                    content: {
                                        width: "600px",
                                        maxHeight: "80%",
                                        position: "relative",
                                        top: "50%",
                                        padding: "16px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        transform: "translateY(-50%)",
                                        transition: "opacity 2s"
                                    }
                                }}
                            >
                                <div id='glass-spec-modal-content'>
                                    <img src={vendor_logo} alt="vendor logo" />
                                    <div className="glass-desc">{this.state.glass_detail.description}</div>
                                    {(() => {
                                        if (this.state.glass_detail.hasOwnProperty("product_url")) {
                                            return this.state.glass_detail.product_url.map(link => <a className="glass-url" rel="noreferrer" key={link.url} href={link.url} target="_blank">{link.text}</a>)
                                        }
                                    })()}
                                    <hr />
                                    {(() => {
                                        if (this.state.glass_modal_mode === "specs") {
                                            return (<>
                                                <table id='glass-modal-spec-table'>
                                                    <tbody>
                                                        <tr>
                                                            <td>U-VALUE [ {this.state.uv_unit}]</td>
                                                            <td>{this.state.glass_detail[this.state.uv_key].toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SOLAR HEAT GAIN COEFFICIENT / SOLAR FACTOR</td>
                                                            <td>{this.state.glass_detail.shgc[0].value.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VISUAL LIGHT TRANSMITTANCE</td>
                                                            <td>{this.state.glass_detail.vlt.toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CALCULATION STANDARD</td>
                                                            <td>{this.state.glass_detail.calculation_standard}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ENVIRONMENTAL PRODUCT DECLARATION</td>
                                                            <td>{this.state.glass_detail.epd ? "Yes" : "No"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>REGION AVAILABITY</td>
                                                            <td>{this.state.glass_detail.regions.join(", ")}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {["vitro", "guardian"].includes(this.state.glass_detail.source) ?
                                                    <button className="theme-btn" onClick={e => this.setState({ glass_modal_mode: "enquiry" })} disabled={this.state.submitting}>Contact Glass Expert</button>
                                                    : ""
                                                }
                                                {this.state.glass_detail.hasOwnProperty("user_id") ?
                                                    <>
                                                        <button className="theme-btn" onClick={e => this.setState({ glass_modal_mode: "share" })} disabled={this.state.submitting}>Share This Custom Make-Up</button>
                                                        <button className="theme-btn delete-btn" onClick={e => this.deleteCustomMakeUp()} disabled={this.state.submitting}>Delete Custom Make-Up</button>
                                                    </>
                                                    : ""
                                                }
                                            </>
                                            )
                                        }
                                        else if (this.state.glass_modal_mode === "enquiry") {
                                            return (
                                                <div>
                                                    <GlassEnquiry glass_data={this.state.glass_detail} />
                                                    <button className="go-back" onClick={() => this.setState({ glass_modal_mode: "specs" })}>« Go Back</button>
                                                </div>
                                            );
                                        }
                                        else if (this.state.glass_modal_mode === "share") {
                                            return (
                                                <div>
                                                    <ShareOptions share_item="custom_glazings" item_id={this.state.glass_detail._id} />
                                                    <button className="go-back" onClick={() => this.setState({ glass_modal_mode: "specs" })}>« Go Back</button>
                                                </div>
                                            )
                                        }
                                    })()}

                                </div>
                            </Modal>
                        )
                    }
                })()}
            </div>
        )
    }

    showDataset() {

        let dataset = this.state.dataset_type === "custom" ? this.state.custom_dataset : this.state.public_dataset;

        if (dataset.length > 0) {
            return dataset.map(glass => {

                let vendor_logo;

                switch (glass.source) {
                    case "guardian": vendor_logo = GuardianLincIcon; break;
                    case "vitro": vendor_logo = VitroLincIcon; break;
                    case "viracon": vendor_logo = ViraconLincIcon; break;
                    default: vendor_logo = FenestraProLincIcon; break;
                }

                return (
                    <tr key={glass._id} onClick={() => this.showGlassDetails(glass)}>
                        {this.state.dataset_type === "custom" ? <td>{glass.shared_with === true ? <span>Shared</span> : <span>Yours</span>}</td> : null}
                        <td>{glass.created_at.substr(0, 10)}</td>
                        <td style={{ textAlign: "center" }}><img className='vendor-logo' src={vendor_logo} alt="vendor logo" /></td>
                        <td style={{ width: "300px" }}>{glass.description}</td>
                        <td>{glass[this.state.uv_key].toFixed(2)}</td>
                        <td>{parseFloat(glass.shgc[0].value).toFixed(2)}</td>
                        <td>{parseFloat(glass.vlt).toFixed(2)}</td>
                        <td>{glass.calculation_standard}</td>
                        <td>{glass.epd === true ? <span style={{ color: "green" }}>✓</span> : <span style={{ color: "red" }}>✘</span>}</td>
                        <td>{glass.regions.join(", ")}</td>
                    </tr>
                );
            });
        }
        else {
            return (
                <tr>
                    <td colSpan="9">
                        No results with these filter parameters
                    </td>
                </tr>
            );
        }

    }

    updateDataset() {

        this.setState({ submitting: true });
        let queryString = "?custom=yes&items=100";

        if (this.state.filter_vendor !== "all") {
            queryString += "&vendor=" + this.state.filter_vendor;
        }
        if (this.state.filter_epd !== "all") {
            queryString += "&epd=" + this.state.filter_epd;
        }
        if (this.state.filter_region !== "all") {
            queryString += "&regions=" + this.state.filter_region;
        }
        if (this.context.settings.numeric_system === "imperial") {
            if (this.state.filter_min_uv > 0) {
                queryString += "&min_uv=" + (this.state.filter_min_uv * 5.678263);
            }
            if (this.state.filter_max_uv < 1.25) {
                queryString += "&max_uv=" + (this.state.filter_max_uv * 5.678263);
            }
        }
        else {
            if (this.state.filter_min_uv > 0) {
                queryString += "&min_uv=" + (this.state.filter_min_uv);
            }
            if (this.state.filter_max_uv < 7) {
                queryString += "&max_uv=" + this.state.filter_max_uv;
            }
        }

        if (this.state.filter_min_shgc > 0) {
            queryString += "&min_shgc=" + this.state.filter_min_shgc;
        }
        if (this.state.filter_max_shgc < 1) {
            queryString += "&max_shgc=" + this.state.filter_max_shgc;
        }
        if (this.state.filter_min_vlt > 0) {
            queryString += "&=min_vlt=" + this.state.filter_min_vlt;
        }
        if (this.state.filter_max_vlt < 1) {
            queryString += "&=max_vlt=" + this.state.filter_max_vlt;
        }
        if (this.state.filter_name !== "") {
            queryString += "&name=" + this.state.filter_name;
        }
        queryString += "&page=" + this.state.page;
        queryString += "&sortby=" + this.state.filter_sort_method;
        API.getLincGlasses(queryString)
            .then(response => {
                this.setState({
                    public_dataset: response.result.data,
                    custom_dataset: response.custom,
                    dataset_length: response.result.totalCount,
                    page_count: response.result.pageCount,
                });
            })
            .catch(error => {
                alert(error);
            })
            .always(() => {

                this.setState({ submitting: false });
            });
    }

    getChartSeries() {
        let dataset = [];
        if (this.state.dataset_type === "public") {
            dataset = this.state.public_dataset;
        }
        else if (this.state.dataset_type === "custom") {
            dataset = this.state.custom_dataset;
        }

        let series = dataset.map(g => {
            let p = {
                x: 0,
                y: 0,
                glass: g,
                uv_key: this.state.uv_key,
                uv_unit: this.state.uv_unit
            }

            if (this.state.chart_x === "U-Value") {
                p.x = g[this.state.uv_key];
            }
            else if (this.state.chart_x === "Visual Light Transmittance") {
                p.x = g.vlt;
            }
            else if (this.state.chart_x === "Solar Heatgain Coefficient") {
                p.x = g.shgc[0].value;
            }

            if (this.state.chart_y === "U-Value") {
                p.y = g[this.state.uv_key];
            }
            else if (this.state.chart_y === "Visual Light Transmittance") {
                p.y = g.vlt;
            }
            else if (this.state.chart_y === "Solar Heatgain Coefficient") {
                p.y = g.shgc[0].value;
            }

            return p;
        });

        return series;
    }

    toggleDatasetType() {

        if (this.state.dataset_type === "public") {
            this.setState({
                dataset_type: "custom"
            });
        }
        else if (this.state.dataset_type === "custom") {
            this.setState({
                dataset_type: "public"
            });
        }
    }

    toggleDisplayType() {
        if (this.state.display_type === "table") {
            this.setState({ display_type: "chart" });
        }
        else if (this.state.display_type === "chart") {

            this.setState({ display_type: "table" });
        }
    }

    showGlassDetails(glass) {
        this.setState({ glass_detail: glass });
    }

    deleteCustomMakeUp() {
        if (window.confirm("Please confirm you really want to delete this custom makeup")) {
            const custom_glass_id = this.state.glass_detail._id;
            this.setState({ submitting: true });
            API.deleteCustomGlassMakeup(custom_glass_id)
                .then(response => {
                    this.setState({
                        submitting: false,
                        glass_detail: null,
                        custom_dataset: this.state.custom_dataset.filter(g => g._id !== custom_glass_id)
                    })
                })
                .catch(error => {
                    this.setState({
                        submitting: false,
                    });
                    console.log(error)
                });
        }
    }
}

GlassDatabase.contextType = AppContext;
export default GlassDatabase;