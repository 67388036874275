import { Component } from 'react';
import AppContext from '../AppContext';
import API from '../Api';
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiselectTwoSides from 'react-multiselect-two-sides';
import { EMAIL_REGEX } from "../Regex";

class Admin extends Component {
    constructor(props){
        super(props);
        this.state = {
            fetching: true,
            submitting: false,
            modal_type: "", //user-details || team-details || machine-details || add-user || add-team  || closed if empty string
            user_details: null,
            team_details: null,
            machine_details: null,
            tab_selected: "license",
            users: [],
            teams: [],
            machines: [],
            organization: {
                _id: "",
                id: "",
                active: false,
                email: "",
                end_date: "",
                license_key: "",
                name: "",
                platform_type: "",
                region: "",
                registration_date: "",
                trial: false,
                user_signin_required: true,
                product_details: {
                    seats: 0, 
                    assigned_users: 0, 
                    cloud_storage: 0, 
                    products: [],
                }
            },
            editable_user: {
                email: "",
                confirm_email: "",
                firstname: "",
                lastname: "",
                username: "",
                is_main: false,
                teams: []
            },
            editable_team: {
                name: "",
                users: [],
            },
            editable_machine: {
                friendly_name: ""
            },

        }
    }

    componentWillMount(){
        Promise.all([
            API.getOrganizationDetails(),
            API.getUsers(),
            API.getTeams(),
            API.getMachines()
        ])
        .then(results => {
            //console.log(results[1]);
            this.setState({
                organization: results[0].organization,
                users: results[1].users,
                teams: results[2].teams,
                machines: results[3].machines,
                fetching: false
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({
                fetching: false
            });
            
        });
    }

    

    render(){
        if(this.state.fetching === true){
            return <div className="loading">Loading&#8230;</div>
        }
        else{
            return (
                <div id='admin-container'>
                    <h1 className="page-title">ADMINISTRATION SETTINGS</h1>
                    <div id='admin-tabs'>
                        <div className='tab-list'>
                            <div onClick={()=>this.setState({ tab_selected: "license"})} className={this.state.tab_selected==="license-details"? "active" : ""}>LICENSE</div>
                            <div onClick={()=>this.setState({ tab_selected: "users"})} className={this.state.tab_selected==="users"? "active" : ""}>USERS</div>
                            <div onClick={()=>this.setState({ tab_selected: "teams"})} className={this.state.tab_selected==="teams"? "active" : ""}>TEAMS</div>
                            <div onClick={()=>this.setState({ tab_selected: "machines"})} className={this.state.tab_selected==="machines"? "active" : ""}>COMPUTERS</div>
                        </div>
                        {(() => {
                            if(this.state.tab_selected === "license"){
                                return(
                                    <div id="organization">
                                    <h1>LICENSE</h1>
                                    <table id='subscription-details-table' className='display'>
                                        <tbody>
                                            <tr>
                                                <td>Organization Name </td>
                                                <td>{this.state.organization.name}</td>
                                            </tr>
                                            <tr>
                                                <td>License Key</td>
                                                <td>{this.state.organization.license_key}</td>
                                            </tr>
                                            <tr>
                                                <td>Subscription Status</td>
                                                <td>{this.state.organization.active ? "Active" : "Not Active"}</td>
                                            </tr>
                                            <tr>
                                                <td>Subscription End Date</td>
                                                <td>{this.state.organization.end_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Seats</td>
                                                <td>{this.state.organization.product_details.seats}</td>
                                            </tr>
                                            <tr>
                                                <td>Assigned Users</td>
                                                <td>{this.state.organization.product_details.assigned_users}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                );
                            }
                            else if(this.state.tab_selected === "users"){
                                return(
                                    <div className='admin_table_container'>
                                        <h1>USERS ({this.state.users.length} of {this.state.organization.product_details.assigned_users})
                                            <button 
                                                style={{float: "right"}} 
                                                disabled={ this.state.submitting || this.state.users.length >=  this.state.organization.product_details.assigned_users } 
                                                className="theme-btn"
                                                onClick={e => this.setState({
                                                    editable_user: {
                                                        email: "",
                                                        confirm_email: "",
                                                        firstname: "",
                                                        lastname: "",
                                                        username: "",
                                                        is_main: false,
                                                        teams: []
                                                    },
                                                    modal_type: "add-user"
                                                })}
                                            > 
                                            Add User
                                            </button>
                                        </h1>
                                        <div className="table-wrapper">
                                            <DataTable
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                dense={true}
                                                keyFied="_id"
                                                onRowClicked={ row => this.setState({
                                                    user_details: row,
                                                    editable_user: {
                                                        firstname: row.firstname,
                                                        lastname: row.lastname,
                                                        username: row.username,
                                                        is_main: row.is_main,
                                                        teams: row.teams
                                                    },
                                                    modal_type: "user-details"
                                                })}
                                                columns={[
                                                    {
                                                        name: 'Email',
                                                        selector: row => row.email,
                                                        sortable: true,
                                                    }, 
                                                    {
                                                        name: 'First Name',
                                                        selector: row => row.firstname,
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Last Name',
                                                        selector: row => row.lastname,
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'User Name',
                                                        selector: row => row.username,
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Verified',
                                                        selector: row => row.verified ? "Yes" : "No",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Admin',
                                                        selector: row => row.is_main ? "Yes" : "No",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Last Login',
                                                        selector: row => row.last_login ||  "-",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Revit Version',
                                                        selector: row => row.revit_version ||  "-",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Msi Version',
                                                        selector: row => row.revit_msi_version ||  "-",
                                                        sortable: true,
                                                    }
                                                ]}
                                                data={this.state.users}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            else if(this.state.tab_selected === "teams"){
                                return(
                                    <div  className='admin_table_container'>
                                        <h1>
                                            TEAMS ({this.state.teams.length})
                                            <button 
                                                style={{float: "right"}} 
                                                disabled={ this.state.submitting } 
                                                className="theme-btn"
                                                onClick={e => this.setState({
                                                    editable_team: {
                                                        name: "",
                                                        users: []
                                                    },
                                                    modal_type: "add-team"
                                                })}
                                            >Add Team</button>
                                        </h1>
                                        <div className="table-wrapper">
                                            <DataTable
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                dense={true}
                                                keyFied="_id"
                                                onRowClicked={ row => this.setState({
                                                    team_details: row,
                                                    editable_team: { 
                                                        name: row.name,
                                                        users: row.users
                                                    },
                                                    modal_type: "team-details"
                                                })}
                                                columns={[
                                                    {
                                                        name: 'Name',
                                                        selector: row => row.name,
                                                        sortable: true,
                                                    }, 
                                                    {
                                                        name: 'Members',
                                                        selector: row => row.users.map(u_id => this.state.users.find(u => u._id === u_id))
                                                            .filter(u => u !== undefined)
                                                            .sort((a,b) => {
                                                                if ( a.email < b.email ){
                                                                    return -1;
                                                                }
                                                                if ( a.email > b.email ){
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            })
                                                            .map(u => <div key={u._id} style={{pointerEvents: "none"}}> {u.email}  [ {u.firstname}  {u.lastname} ]</div>)
                                                            
                                                    }
                                                ]}
                                                data={this.state.teams}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            else if(this.state.tab_selected === "machines"){
                                return(
                                    <div className='admin_table_container'>
                                        <h1>COMPUTERS ({this.state.machines.length})</h1>
                                        <div className="table-wrapper">
                                            <DataTable
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                dense={true}
                                                keyFied="_id"
                                                onRowClicked={ row => this.setState({
                                                    machine_details: row,
                                                    editable_machine: {
                                                        friendly_name: row.friendly_name
                                                    },
                                                    modal_type: "machine-details"
                                                })}
                                                columns={[
                                                    {
                                                        name: 'Computer',
                                                        selector: row => row.name,
                                                        sortable: true,
                                                    }, 
                                                    {
                                                        name: 'Tag Name',
                                                        selector: row => row.friendly_name,
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Msi version',
                                                        selector: row => row.msi_version || "Unknown - requires version update",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Revit Version',
                                                        selector: row => row.revit_version || "Unknown - requires version update",
                                                        sortable: true,
                                                    }
                                                ]}
                                                data={this.state.machines}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            else{
                                return <></>
                            }
                        })()}
                        {(() => {
                            if (this.state.modal_type !== "") {
                                return (
                                    <Modal ariaHideApp={false} isOpen={true} onRequestClose={() => this.setState({user_details: null, team_details: null, machine_details: null, modal_type: ""})} contentLabel="Example Modal"
                                        style={{
                                            overlay: {
                                                position: 'fixed',
                                                background: 'rgba(0,0,0,.5)'
                                            },
                                            content: {
                                                width: "600px",
                                                maxHeight: "80%",
                                                position: "relative",
                                                top: "50%",
                                                padding: "16px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                transform: "translateY(-50%)",
                                                transition: "opacity 2s"
                                            }
                                        }}
                                    >
                                        {this.showModalContent()}
                                    </Modal>
                                )
                            }
                        })()}
                    </div>
                    <ToastContainer position="bottom-right" autoClose="1500" />
                </div>
            )
        }
    }

    updateTeamsAndUsers(){
        this.setState({
            submitting: true,
            modal_type: "",
            editable_user: {
                email: "",
                confirm_email: "",
                firstname: "",
                lastname: "",
                username: "",
                is_main: false,
                teams: []
            },
            editable_team: {
                name: "",
                users: [],
            }
        });
        Promise.all([
            API.getUsers(),
            API.getTeams(),
        ])
        .then(results => {
           
            this.setState({
                users: results[0].users,
                teams: results[1].teams,
                submitting: false
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({
                submitting: false
            });
        });
    }

    showModalContent(){
        if(this.state.modal_type === "user-details"){
            return (
                <div>
                    <h2>User</h2>
                    <table className="modal-table-desc">
                        <tbody>
                            <tr>
                                <td>
                                    User Id
                                </td>
                                <td>
                                    {this.state.user_details._id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    {this.state.user_details.email}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    First Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.firstname} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {firstname: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.lastname} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {lastname: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Username
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.username} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {username: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Is Admin
                                </td>
                                <td>
                                    <input className="theme-input" type="checkbox" value={this.state.editable_user.is_main} checked={this.state.editable_user.is_main} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {is_main: e.target.checked})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <MultiselectTwoSides
                                        options= {this.state.teams.map(t => {
                                            return {
                                                name: t.name,
                                                value: t._id 
                                            }
                                        })}
                                        value = {this.state.editable_user.teams}
                                        className="msts_theme_example"
                                        availableHeader="Available Teams"
                                        availableFooter={"Available: "+ (this.state.users.length - this.state.editable_team.users.length) }
                                        selectedHeader="Selected Teams"
                                        selectedFooter={"Selected: " + this.state.editable_team.users.length}
                                        placeholder= 'Filter Teams'
                                        labelKey="name"
                                        onChange={value => this.onUserTeamsChange(value)}
                                        showControls
                                        searchable
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center">
                        <button className="theme-btn delete-btn" disabled={this.state.submitting} onClick={e => this.deleteUser()}>Delete User</button>
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.resendInvite()}>Re-send Invite Email</button>
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.editUser()}>Save Changes</button>
                    </div>
                </div>
            )
        }
        else if(this.state.modal_type === "team-details"){
            return (
                <div>
                    <h2>Team</h2>
                    <table className="modal-table-desc">
                        <tbody>
                            <tr>
                                <td>
                                    Team Id
                                </td>
                                <td>
                                    {this.state.team_details._id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Team Name
                                </td>
                                <td>
                                <input className="theme-input" type="text" value={this.state.editable_team.name} onChange={e => this.setState({editable_team: Object.assign({}, this.state.editable_team, {name: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                <MultiselectTwoSides
                                    options= {this.state.users.map(u => {
                                        return {
                                            name: u.firstname+" "+u.lastname+ " "+u.email,
                                            value: u._id 
                                        }
                                    })}
                                    value = {this.state.editable_team.users}
                                    className="msts_theme_example"
                                    availableHeader="Available Users"
                                    availableFooter={"Available: "+ (this.state.users.length - this.state.editable_team.users.length) }
                                    selectedHeader="Selected Users"
                                    selectedFooter={"Selected: " + this.state.editable_team.users.length}
                                    placeholder= 'Filter Users'
                                    labelKey="name"
                                    onChange={value => this.onTeamUsersChange(value)}
                                    showControls
                                    searchable
                                />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center">
                        <button className="theme-btn delete-btn" disabled={this.state.submitting} onClick={e => this.deleteTeam()}>Delete Team</button>
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.editTeam()}>Save Changes</button>
                    </div>
                </div>
            )
        }
        else if(this.state.modal_type === "machine-details"){
            return (
                <div>
                    <h2>Computer</h2>
                    <table className="modal-table-desc">
                        <tbody>
                            <tr>
                                <td>
                                   Computer Id
                                </td>
                                <td>
                                    {this.state.machine_details._id}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MSI Version
                                </td>
                                <td>
                                    {this.state.machine_details.msi_version}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Revit Version
                                </td>
                                <td>
                                {this.state.machine_details.revit_version}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tag Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_machine.friendly_name} onChange={e => this.setState({editable_machine: Object.assign({}, this.state.editable_machine, {friendly_name: e.target.value})})}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center">
                        <button className="theme-btn delete-btn" disabled={this.state.submitting} onClick={e => this.deleteComputer()}>Remove Computer</button>
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.editComputer()}>Save Changes</button>
                    </div>
                    
                </div>
            )
        }
        else if(this.state.modal_type === "add-user"){
            return (
                <div>
                    <h2>New User</h2>
                    <table className="modal-table-desc">
                        <tbody>
                            <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.email} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {email: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Confirm Email
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.confirm_email} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {confirm_email: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    First Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.firstname} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {firstname: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.lastname} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {lastname: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Username
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_user.username} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {username: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Is Admin
                                </td>
                                <td>
                                    <input className="theme-input" type="checkbox" value={this.state.editable_user.is_main} checked={this.state.editable_user.is_main} onChange={e => this.setState({editable_user: Object.assign({}, this.state.editable_user, {is_main: e.target.checked})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <MultiselectTwoSides
                                        options= {this.state.teams.map(t => {
                                            return {
                                                name: t.name,
                                                value: t._id 
                                            }
                                        })}
                                        value = {this.state.editable_user.teams}
                                        className="msts_theme_example"
                                        availableHeader="Available Teams"
                                        availableFooter={"Available: "+ (this.state.users.length - this.state.editable_team.users.length) }
                                        selectedHeader="Selected Teams"
                                        selectedFooter={"Selected: " + this.state.editable_team.users.length}
                                        placeholder= 'Filter Teams'
                                        labelKey="name"
                                        onChange={value => this.onUserTeamsChange(value)}
                                        showControls
                                        searchable
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="align-center">
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.addUser()}>Add User</button>
                    </div>
                    
                </div>
            )
        }
        else if(this.state.modal_type === "add-team"){
            return (
                <div>
                    <h2>New Team</h2>
                    <table className="modal-table-desc">
                        <tbody>
                            <tr>
                                <td>
                                    Team Name
                                </td>
                                <td>
                                    <input className="theme-input" type="text" value={this.state.editable_team.name} onChange={e => this.setState({editable_team: Object.assign({}, this.state.editable_team, {name: e.target.value})})}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <MultiselectTwoSides
                                        options= {this.state.users.map((u,i) => {
                                            return {
                                                name: u.firstname+" "+u.lastname+ " "+u.email,
                                                value: u._id 
                                            }
                                        })}
                                        value = {this.state.editable_team.users}
                                        className="msts_theme_example"
                                        availableHeader="Available Users"
                                        availableFooter={"Available: "+ (this.state.users.length - this.state.editable_team.users.length) }
                                        selectedHeader="Selected Users"
                                        selectedFooter={"Selected: " + this.state.editable_team.users.length}
                                        placeholder= 'Filter Users'
                                        labelKey="name"
                                        onChange={value => this.onTeamUsersChange(value)}
                                        showControls
                                        searchable
                                    />
                                </td>
                               
                            </tr>
                            
                        </tbody>
                    </table>
                    <div className="align-center">
                        <button className="theme-btn" disabled={this.state.submitting} onClick={e => this.addTeam()}>Add Team</button>
                    </div>
                </div>
            )
        }
        else{
            return <></>;
        }
    }
    
    addUser(){
        let email = this.state.editable_user.email.trim();
        let confirm_email = this.state.editable_user.confirm_email.trim();
        let firstname = this.state.editable_user.firstname.trim();
        let lastname = this.state.editable_user.lastname.trim();
        let username = this.state.editable_user.username.trim();
        let is_main = this.state.editable_user.is_main;
        let teams = this.state.editable_user.teams;
        if(!EMAIL_REGEX.test(email)){
            return toast.error("The email has an invalid format.", { autoClose: 5000 });
        }
        if(email !== confirm_email){
            return toast.error("Email confirm is not the same", { autoClose: 5000 });
        }
        if(firstname.length === 0 || lastname.length === 0){
            return toast.error("First name and Last name are required", { autoClose: 5000 });
        }
        else{
            API.addUser(email, confirm_email, firstname, lastname, username, is_main, teams)
            .then(new_user => {
                toast.success("User added.");
                this.updateTeamsAndUsers();
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({submitting: false});
            });
        }
    }

    editUser(){
        
        this.setState({submitting: true})
        let user_id = this.state.user_details._id;
        let firstname = this.state.editable_user.firstname;
        let lastname = this.state.editable_user.lastname;
        let username = this.state.editable_user.username;
        let is_main = this.state.editable_user.is_main;
        let teams = this.state.editable_user.teams;
        API.editUser(user_id, firstname, lastname, username, is_main, teams)
        .then(() => {
            this.updateTeamsAndUsers();
            toast.success("User Edited.");
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    resendInvite(){
        this.setState({submitting: true})
        let user_id = this.state.user_details._id;
        API.sendInvite(user_id)
        .then(() => {
            toast.success("Invitation email sent.");
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    deleteUser(){
        if(window.confirm("Confirm that you want to remove this user, the computer can be readded later again but the user will need to re-enter the license key again.")){
            this.setState({submitting: true})
            let user_id = this.state.user_details._id;
            API.deleteUser(user_id)
            .then(() => {
                this.updateTeamsAndUsers();
                toast.success("User deleted.");
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({submitting: false});
            });
        }
    }   

    addTeam(){
        let name = this.state.editable_team.name;
        let users = this.state.editable_team.users;
        this.setState({submitting: true});
        API.addTeam(name, users)
        .then(response => {
            toast.success("Team added");
            this.updateTeamsAndUsers();
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    editTeam(){
        let team_id = this.state.team_details._id;
        let team_name = this.state.editable_team.name;
        let user_ids = this.state.editable_team.users;
        this.setState({submitting: true});
        API.editTeam (team_id, team_name, user_ids)
        .then(response => {
            toast.success("Team updated");
            this.updateTeamsAndUsers();
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    deleteTeam(){
        //TODO
        let team_id = this.state.team_details._id;
      
        this.setState({submitting: true});
        API.deleteTeam(team_id)
        .then(response => {
            toast.success("Team deleted");
            this.updateTeamsAndUsers();
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    editComputer(){
        
        this.setState({submitting: true})
        let machine_id = this.state.machine_details.machine_id;
        let machine_name = this.state.editable_machine.friendly_name;

        API.editMachine(machine_id, machine_name)
        .then(() => {
            this.setState({
                machines: this.state.machines.map(m => this.state.machine_details._id === m._id ? Object.assign({} ,m, this.state.editable_machine) : m),
                machine_details: null,
                modal_type: ""
            });
            toast.success("Computer edited.");
        })
        .catch(error => {
            toast.error(error.responseText, { autoClose: 5000 });
        })
        .always(() => {
            this.setState({submitting: false});
        });
    }

    deleteComputer(){
        if(window.confirm("Confirm that you want to remove this computer, the computer can be readded later again but the user will need to re-enter the license key again.")){
            this.setState({submitting: true})
            let machine_id = this.state.machine_details.machine_id;
            API.deleteMachine(machine_id)
            .then(() => {
                this.setState({
                    machines: this.state.machines.filter(m => m.machine_id !== machine_id),
                    machine_details: null,
                    modal_type: ""
                });
                toast.success("Computer removed.");
            })
            .catch(error => {
                toast.error(error.responseText, { autoClose: 5000 });
            })
            .always(() => {
                this.setState({submitting: false});
            });
        }
       
    }

    onTeamUsersChange(value){
        this.setState({
            editable_team: Object.assign({}, this.state.editable_team, {users: value})
        });
    }

    onUserTeamsChange(value){
        this.setState({
            editable_user: Object.assign({}, this.state.editable_user, {teams: value})
        });
    }
}

Admin.contextType = AppContext;

export default Admin;